import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getJobDetails,
  postJobCreate,
  putJobUpdate,
} from "../../redux/Actions/jobActions";
import { imageUploadAdmin } from "../../redux/Actions/userActions";

import {
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
} from "@mui/material";

import BasicFields from "../../components/AddJob/BasicFields";
import OverviewFields from "../../components/AddJob/OverviewFields";
import SettingFields from "../../components/AddJob/SettingFields";
import QuestionFields from "../../components/AddJob/QuestionFields";
import RoleIndusFields from "../../components/AddJob/RoleIndusFields";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const EditJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let type = searchParams.get("type");
  let jobId = searchParams.get("jobId");

  const { userInfo } = useSelector((state: any) => state.userDetails);
  const { job } = useSelector((state: any) => state.jobDetail);
  const { loading, success } = useSelector((state: any) => state.jobCreate);
  const { loading: loadingUpdate, success: successUpdate } = useSelector(
    (state: any) => state.jobUpdate
  );
  const { imageLink } = useSelector((state: any) => state.adminImageUpload);

  const [err, setErr] = useState({ status: false, message: "" });
  const [jobData, setJobData] = useState({
    access: [],
    address: { city: "", cities: [] as string[], country: "" },
    alert: false,
    application: [],
    auto_decline: false,
    auto_decline_duration: 0,
    closed: false,
    created_by: {
      _id: "",
      banner: null,
      bio: "",
      display_pictures: [],
      education: null,
      education_level: 0,
      first_name: "",
      headline: "",
      last_name: "",
      preferences: {
        objectives: [],
        preferred_careers: [],
        preferred_industries: [],
        interests: [],
      },
      socials: [{ name: "", social_type: 0, url: "" }],
      user_name: "",
      work_experiences: [],
    },
    deadline: "",
    description: "",
    display_picture: "",
    experience: "",
    experience_required: 0,
    firm_overview: "",
    hidden: false,
    industries: [] as string[],
    job_questions: [] as any[],
    job_roles: [] as string[],
    job_score_enabled: false,
    job_score_parameters: null,
    job_title: "",
    job_type: 1,
    job_workspaces: null,
    organisation_id: "",
    organisations: [] as string[],
    redirect: "",
    schedule_date: "",
    start_date: "",
    tagline: "",
    tags: [] as string[],
    website: "",
  });

  useEffect(() => {
    if (userInfo) {
      setJobData({
        ...jobData,
        created_by: {
          ...jobData.created_by,
          _id: userInfo?._id,
          banner: userInfo?.banner,
          bio: userInfo?.bio,
          display_pictures: userInfo?.display_pictures,
          education: userInfo?.education,
          education_level: userInfo?.education_level,
          first_name: userInfo?.first_name,
          headline: userInfo?.headline,
          last_name: userInfo?.last_name,
          preferences: userInfo?.preferences,
          socials: userInfo?.socials,
          user_name: userInfo?.user_name,
          work_experiences: userInfo?.work_experiences,
        },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (imageLink) {
      setJobData({
        ...jobData,
        display_picture:
          imageLink?.records?.length > 0 ? imageLink.records[0].url : "",
      });
    }
  }, [imageLink]);

  useEffect(() => {
    if (type === null) {
      navigate("?type=new");
    } else if (type === "existing" && jobId) {
      dispatch(getJobDetails(jobId));
    } else if (type === "existing" && !jobId) {
      navigate("?type=new");
    }
  }, [type, jobId]);

  useEffect(() => {
    if (type === "existing" && job) {
      setJobData(job);
    }
  }, [job]);

  const submitHandler = () => {
    if (jobData.job_title === "") {
      setErr({ status: true, message: "Job title can not be empty." });
    } else if (jobData.organisations.length === 0) {
      setErr({ status: true, message: "Organisation can not be empty." });
    } else if (jobData.address.cities.length === 0) {
      setErr({ status: true, message: "Cities can not be empty." });
    } else if (jobData.address.country === "") {
      setErr({ status: true, message: "Country can not be empty." });
    } else if (jobData.description === "") {
      setErr({ status: true, message: "Role Details can not be empty." });
    } else if (jobData.start_date === "") {
      setErr({ status: true, message: "Start Date can not be empty." });
    } else if (jobData.deadline === "") {
      setErr({ status: true, message: "Deadline can not be empty." });
    } else if (jobData.tags.length === 0) {
      setErr({ status: true, message: "Tags can not be empty." });
    } else if (jobData.firm_overview === "") {
      setErr({ status: true, message: "Firm Details can not be empty." });
    } else if (jobData.experience === "") {
      setErr({ status: true, message: "Experience Details can not be empty." });
    } else if (jobData.job_roles.length === 0) {
      setErr({ status: true, message: "Roles can not be empty." });
    } else if (jobData.industries.length === 0) {
      setErr({ status: true, message: "Industries can not be empty." });
    } else {
      if (type === "new") {
        dispatch(postJobCreate(jobData));
      } else {
        dispatch(putJobUpdate(jobData));
      }
    }
  };

  const fileUploadHandler = (e: any) => {
    const formData = new FormData();
    formData.append("folder", "job");
    formData.append("files", e.target.files[0]);

    dispatch(imageUploadAdmin(formData));
  };
  // console.log(jobData.display_picture);

  return (
    <div className="edit-job">
      <div className="head-text">
        <h1>Welcome!</h1>
        <h2>Add new Job.</h2>
      </div>

      <div className="all-details">
        <div className="basic">
          <h2>Basic Details</h2>

          <BasicFields data={jobData} update={setJobData} />
        </div>

        <div className="overviews">
          <h2>Overviews</h2>

          <OverviewFields data={jobData} update={setJobData} />
        </div>

        <div className="media">
          <h2>Media</h2>

          <div className="media_fields">
            <div className="row">
              <Typography variant="h6" component="h6">
                Display Picture:
              </Typography>
              <input
                type="file"
                onChange={fileUploadHandler}
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <div className="role-n-industries">
          <h2>Roles and Industries</h2>

          <RoleIndusFields data={jobData} update={setJobData} />
        </div>

        <div className="settings">
          <h2>Settings</h2>

          <SettingFields data={jobData} update={setJobData} />
        </div>

        <div className="questions">
          <h2>Questions</h2>

          <QuestionFields data={jobData} update={setJobData} />
        </div>
      </div>

      <div className="submit">
        {err.status && (
          <Alert severity="error">
            <AlertTitle style={{ textAlign: "left" }}>
              Incomplete Data
            </AlertTitle>
            {err.message}
          </Alert>
        )}
        {loading || loadingUpdate ? (
          <CircularProgress />
        ) : (
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        )}
      </div>

      <Dialog
        open={success || successUpdate}
        onClose={() => window.location.reload()}
      >
        <DialogContent>
          <h2>Job Created Successfully!!</h2>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()} variant="contained">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LayoutsSide(EditJob);
