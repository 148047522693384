import React from "react";
import SummaryBanner from "../../components/Summary/SummaryBanner";
import LayoutsSide from "../../components/Layout/LayoutsSide";
import SummaryChart from "../../components/Summary/SummaryChart";
import WorkShop from "../../components/Summary/WorkShop";

const Summary = () => {
  return (
    <div className="summary">
      <SummaryBanner />
      <SummaryChart />
      <h1 style={{color:"#160D3A",fontWeight:"600",fontSize:"23px",marginBottom:"20px"}}>Recent Workshops</h1>
      <WorkShop />
    </div>
  );
};

export default LayoutsSide(Summary);
