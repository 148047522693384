import React, { useState, FC } from "react";
import { Dialog, AppBar, Toolbar, Slide, Button } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: FC<{ children: any; open: boolean; setOpen: any }> = ({
  children,
  open,
  setOpen,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Button autoFocus color="inherit" onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </Toolbar>
      </AppBar>
      <div
        className="joblist"
        style={{ paddingLeft: "1%", paddingRight: "1%" }}
      >
        {children}
      </div>
    </Dialog>
  );
};

export default FullScreenDialog;
