import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import {
  networkingObjs,
  interests,
  careerChoice,
  industryChoice,
} from "../../helpers/content/preferences";

const PreferenceFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const preferenceHandler = (newVal: any, input: string) => {
    setUserData({
      ...userData,
      preferences: {
        ...userData.preferences,
        [input]: newVal,
      },
    });
  };

  return (
    <div className="preference_fields">
      <Autocomplete
        multiple
        disableClearable
        id="tags-outlined"
        value={userData.preferences.preferred_careers}
        onChange={(e, newVal) => preferenceHandler(newVal, "preferred_careers")}
        options={careerChoice}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add career interests here (Type and press Enter)"
            placeholder="Career Interests"
          />
        )}
      />

      <Autocomplete
        multiple
        disableClearable
        id="tags-outlined"
        value={userData.preferences.preferred_industries}
        onChange={(e, newVal) =>
          preferenceHandler(newVal, "preferred_industries")
        }
        options={industryChoice}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add industry interests here (Type and press Enter)"
            placeholder="Industry Interests"
          />
        )}
      />

      <Autocomplete
        multiple
        disableClearable
        id="tags-outlined"
        value={userData.preferences.objectives}
        onChange={(e, newVal) => preferenceHandler(newVal, "objectives")}
        options={networkingObjs}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add networking objectives here (Type and press Enter)"
            placeholder="Networking Objectives"
          />
        )}
      />

      <Autocomplete
        multiple
        disableClearable
        id="tags-outlined"
        value={userData.preferences.interests}
        onChange={(e, newVal) => preferenceHandler(newVal, "interests")}
        options={interests}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add interests here (Type and press Enter)"
            placeholder="Interests"
          />
        )}
      />
    </div>
  );
};

export default PreferenceFields;
