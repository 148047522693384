import {
  EMAIL_TEMPLATE_LIST_REQUEST,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_LIST_FAIL,
  EMAIL_FILTER_LIST_REQUEST,
  EMAIL_FILTER_LIST_SUCCESS,
  EMAIL_FILTER_LIST_FAIL,
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
} from "../Constants/emailConstants";

export const emailTemplateListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EMAIL_TEMPLATE_LIST_REQUEST:
      return { loading: true };

    case EMAIL_TEMPLATE_LIST_SUCCESS:
      return {
        templates: action.payload,
        loading: false,
        error: false,
      };

    case EMAIL_TEMPLATE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const emailFilterListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EMAIL_FILTER_LIST_REQUEST:
      return { loading: true };

    case EMAIL_FILTER_LIST_SUCCESS:
      return {
        filters: action.payload,
        loading: false,
        error: false,
      };

    case EMAIL_FILTER_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const emailSendReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EMAIL_SEND_REQUEST:
      return { loading: true };

    case EMAIL_SEND_SUCCESS:
      return {
        sentData: action.payload,
        loading: false,
        success: true,
        error: false,
      };

    case EMAIL_SEND_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
