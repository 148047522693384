import React, { FC } from "react";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";

const PieChart: React.FC<{
  data: {
    records: {
      _id: String;
      count: Number;
    }[];
  };
  view: String;
}> = ({ data, view }) => {
  const key: any = [];
  const data1: any = [];
  const data2: any = [];
  let count = 0;
  //   console.log("pie",data)

  data?.records?.map(
    (university: any) => (
      (count = count + university.count), key.push(university?._id)
    )
  );

  function pushToAry(name: any, val: any) {
    var per = (val / count) * 100;
    var rounded = Math.round(per * 10) / 10;
    var obj: any = {};
    var obj2: any = {};
    obj["id"] = name;
    obj["label"] = name;
    obj["value"] = val;

    data1.push(obj);
    obj2["id"] = name;
    obj2["label"] = name;
    obj2["value"] = rounded;
    data2.push(obj2);
  }

  data?.records?.map((university: any) =>
    pushToAry(university._id, university.count)
  );
  console.log("piid", data2, count);
  let sorted_data = data2.sort((a: any, b: any) => {
    return b.value - a.value;
  });

 

  return (
    <div className="pie-Chart">
      <div
        className="pie-chart-graph"
        style={{  alignItems: "center", marginTop: "70px" }}
      >
        <div className="pie1">
          {data2.length !== 0 && (
            <div style={{ marginTop: "-20px" }}>
              {" "}
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "university" && "University By Number"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "diversity" && "Diversity By Number"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "graduation_year" && "Graduation Year By Number"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "job_function" && "Job Function By Number"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "gpa" && "GPA By Number "}
              </p>
            </div>
          )}
          <ResponsivePie
            data={data1}
            margin={{ top: 40, right: 80, bottom: 40, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={[
              "#FCA3CC",
              "#BEAEE2",
              "#FCD8D4",
              "#FFAFAF",
              "#D77FA1",
              "#EDD2F3",
              "#FFDEFA",
              "#F5ABC9",
              "#FDE0DD",
              "#FFC288",
              "#B8B5FF",
              "#F190B7",
              "#FCD1D1",
              "#6FB2D2",
              "#F473B9",
              "#F7F7F7",
              "#FCF8EC",
              "#D2F5E3",
              "#FFC1F3",
              "#FCCBCB",
            ]}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsStraightLength={23}
            
            arcLabelsRadiusOffset={0.85}
            arcLinkLabelsTextOffset={1}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            legends={[]}
          />
        </div>
        <div  className="pie2">
          {data2.length !== 0 && (
            <div >
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "university" && "University By Percentage %"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "diversity" && "Diversity By Percentage %"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "graduation_year" &&
                  "Graduation Year By Percentage %"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "job_function" && "Job Function By Percentage %"}
              </p>
              <p className="pie" style={{ marginBottom: "20px" }}>
                {view === "gpa" && "GPA By Percentage %"}
              </p>
            </div>
          )}
          <ResponsivePie
          
            data={sorted_data}
           
            margin={{ top: 40, right: 190, bottom: 80, left: 100}}
            innerRadius={0.1}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsRadiusOffset={0.8}
            arcLinkLabelsStraightLength={0}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            colors={[
              "#FCA3CC",
              "#BEAEE2",
              "#FCD8D4",
              "#FFAFAF",
              "#D77FA1",
              "#EDD2F3",
              "#FFDEFA",
              "#F5ABC9",
              "#FDE0DD",
              "#FFC288",
              "#B8B5FF",
              "#F190B7",
              "#FCD1D1",
              "#6FB2D2",
              "#F473B9",
              "#F7F7F7",
              "#FCF8EC",
              "#D2F5E3",
              "#FFC1F3",
              "#FCCBCB",
            ]}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -90,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
           
            // legends={[
            //     {
            //         anchor: 'top-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 100,
            //         translateY: -30,
            //         itemsSpacing: 0,
            //         itemWidth: 100,
            //         itemHeight: 18,
            //         itemTextColor: '#999',
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 1,
            //         symbolSize: 12,
            //         symbolShape: 'circle',
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemTextColor: '#000'
            //                 }
            //             }
            //         ],

            //     }
            // ]}
            legends={[]}
           
            valueFormat={(value) => `${Number(value)}%`}
          />
        </div>
      </div>
      
    </div>
  );
};

export default PieChart;
