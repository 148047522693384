import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPasswordUpdate } from "../../redux/Actions/userActions";

import { TextField, Button, CircularProgress } from "@mui/material";

import { strongPassword } from "../../helpers/checks/BasicChecks";
import LayoutsSide from "../../components/Layout/LayoutsSide";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const { success, error, loading } = useSelector(
    (state: any) => state.userPasswordChange
  );

  const [current, setCurrent] = useState("");
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");

  const clickHandler = () => {
    if (current !== "" && strongPassword.test(pass) && pass === confirm) {
      dispatch(
        userPasswordUpdate({
          old_password: current,
          new_password: pass,
          confirm_password: confirm,
        })
      );
    }
  };

  console.log(error)

  return (
    <div className="change-password">
      <h1>Change password</h1>
      <div className="input-fields">
        <TextField
          type="password"
          label="Current Password"
          value={current}
          onChange={(e) => setCurrent(e.target.value)}
        />
        <TextField
          type="password"
          label="New Password"
          error={pass !== "" && !strongPassword.test(pass)}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
        <TextField
          type="password"
          label="Confirm New Password"
          error={confirm !== "" && confirm !== pass}
          helperText={
            confirm !== "" && confirm !== pass ? "Passwords do not match" : ""
          }
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
        />
      </div>
      {error && <p style={{color: "red"}}>{error.data.message}</p>}
      {success && <p>Password changed successfully!</p>}
      {loading ? (
        <CircularProgress />
      ) : (
        <Button onClick={clickHandler} variant="contained">
          Change
        </Button>
      )}
    </div>
  );
};

export default LayoutsSide(ChangePassword);
