import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAdmin } from "../../redux/Actions/userActions";

import { Typography } from "@mui/material";

const MediaFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {
  const dispatch = useDispatch();

  const [imgType, setImgType] = useState("");

  const { imageLink, loading } = useSelector(
    (state: any) => state.adminImageUpload
  );

  useEffect(() => {
    if (imageLink) {
      if (imgType === "display_picture") {
        update({
          ...data,
          display_picture:
            imageLink?.records?.length > 0 ? imageLink.records[0].url : "",
        });
      } else if (imgType === "images") {
        const tempData = { ...data };
        tempData.images[0] =
          imageLink?.records?.length > 0 ? imageLink.records[0].url : "";
        update(tempData);
      }
    }
  }, [imageLink, imgType]);

  const displayPictureHandler = (e: any) => {
    const formData = new FormData();
    formData.append("folder", "event");
    formData.append("files", e.target.files[0]);
    setImgType("display_picture");

    dispatch(imageUploadAdmin(formData));
  };
  const bannerImageHandler = (e: any) => {
    const formData = new FormData();
    formData.append("folder", "event");
    formData.append("files", e.target.files[0]);
    setImgType("images");

    dispatch(imageUploadAdmin(formData));
  };

  return (
    <div className="media_fields">
      <div className="row">
        <Typography variant="h6" component="h6">
          Display Picture:
        </Typography>
        <input
          disabled={loading}
          type="file"
          accept="image/*"
          onChange={displayPictureHandler}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Banner Image:
        </Typography>
        <input
          disabled={loading}
          type="file"
          accept="image/*"
          onChange={bannerImageHandler}
        />
      </div>
    </div>
  );
};

export default MediaFields;
