import React from "react";

import { TextField } from "@mui/material";

const MetricsFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const basicDetailHandler = (e: any, input: any) => {
    setUserData({ ...userData, [input]: e.target.value });
  };

  return (
    <div className="metric_fields">
      <div className="row">
        <TextField
          type="number"
          label="UG GPA"
          value={userData.gpa}
          onChange={(e) => basicDetailHandler(e, "gpa")}
        />
        <TextField
          type="number"
          label="SAT (1600)"
          value={userData.sat}
          onChange={(e) => basicDetailHandler(e, "sat")}
        />
        <TextField
          type="number"
          label="ACT"
          value={userData.act}
          onChange={(e) => basicDetailHandler(e, "act")}
        />
        <TextField
          type="number"
          label="GMAT"
          value={userData.gmat}
          onChange={(e) => basicDetailHandler(e, "gmat")}
        />
      </div>

      <div className="row">
        <TextField
          type="number"
          label="Connection Count"
          value={userData.connection_count}
          onChange={(e) => basicDetailHandler(e, "connection_count")}
        />
        <TextField
          type="number"
          label="Redeem Points"
          value={userData.redeem_points}
          onChange={(e) => basicDetailHandler(e, "redeem_points")}
        />
        <TextField
          label="Referral Code"
          value={userData.referral_code}
          onChange={(e) => basicDetailHandler(e, "referral_code")}
        />
      </div>
    </div>
  );
};

export default MetricsFields;
