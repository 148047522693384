export const networkingObjs = [
  "Meet peers with similar professional interests",
  "Meet peers headed to similar firms",
  "Discuss breaking into Sellside (e.g. IB)",
  "Discuss breaking into Buyside (e.g. PE/VC/HF)",
  "Practice interview questions / Prep",
  "Be an entrepreneur: Find co-founder / team",
  "Find PE/VC investors for your company",
  "Provide mentorship",
];

export const interests = [
  "Crypto",
  "Artificial Intelligence",
  "Data Science",
  "Sustainability",
  "Public Investing",
  "Consulting",
  "Marketing",
  "Entrepreneurship",
  "Film",
  "Fintech",
  "Angel Investing",
  "Education",
  "Social Impact",
  "NFT",
  "Travel",
  "Entertainment ",
  "Art",
  "Fitness",
  "Gaming",
  "Food",
  "Sports",
];

export const careerChoice = [
  "Investment Banking",
  "Private Equity",
  "Venture Capital",
  "Hedge Funds",
  "Management Consulting",
  "Technology",
  "Start-Ups / Entrepreneurship",
  "Corporate Finance / Strategy / Operations",
];

export const industryChoice = [
  "Tech",
  "Media & Telecom",
  "Financial Institutions",
  "Healthcare",
  "Industrials",
  "Consumer & Retail",
  "Real Estate",
  "Natural Resources",
  "ESG",
];

export const affinityGroups = [
  "Women",
  "Black",
  "Latinx",
  "Asian",
  "Multi-Racial",
  "LGBTQ+",
  "Disability",
  "Other Diversity",
  "None of the Above",
  "Prefer not to say",
];

export const jobFunction = [
  "Investment Banking - Classic",
  "Investment Banking - Capital Markets",
  "Private Equity",
  "Growth Equity",
  "Venture Capital",
  "Hedge Funds",
  "Management Consulting",
  "Sales & Trading",
  "Asset Management",
  "Wealth Management",
  "Start-up / Entrepreneurship",
  "Technology",
  "Corporate Strategy / Finance",
];

export const cityNames = [
  "New York City",
  "Los Angeles",
  "Chicago",
  "Washington DC",
  "San Francisco",
  "Boston",
  "Toronto",
  "Philadelphia",
  "Dallas-Fort Worth",
  "Houston",
  "Miami",
  "Atlanta",
  "Detroit",
  "San Diego",
  "Phoenix",
  "Seattle",
  "Tampa",
  "Montréal",
  "Denver",
  "Orlando",
  "Minneapolis-St. Paul",
  "Cleveland",
  "Vancouver",
  "Cincinnati",
  "Salt Lake City",
  "Charlotte",
  "Portland",
  "St. Louis",
  "Las Vegas",
  "San Antonio",
  "Sacramento",
  "Indianapolis",
  "Pittsburgh",
  "Kansas City",
  "San Juan",
  "San Salvador",
  "Columbus",
];
