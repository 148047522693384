import React, { useState, useEffect, Fragment, useCallback } from "react";

import {
  DataGridPro,
  GridCellParams,
  GridToolbar,
  MuiEvent,
  GridRenderCellParams,
  GridApi,
  GridColumns,
  gridColumnVisibilityModelSelector,
  GridEvents,
  GridRowGroupingModel,
  useGridApiRef,
  GridRowHeightParams,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import {
  Chip,
  Modal,
  Stack,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  TextField,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { KeyboardArrowRight, FileDownloadOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { Document, Page, pdfjs } from "react-pdf";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment-timezone";

import AffinityChips from "../../components/UI/AffinityChips";
import LayoutsSide from "../../components/Layout/LayoutsSide";
import {
  bulkDownloadAllUsers,
  bulkDownloadAllFinnectUsers,
  getFinnectUserList,
  getUserList,
  getFilteredUserList,
  getSearchedUserList,
  getSearchedFinnectUserList,
} from "../../redux/Actions/userActions";
import FullScreenDialog from "../../components/UI/FullScreenDialog";

import debounce from "../../helpers/debounce";

const BASE = process.env.REACT_APP_BACKEND;

const AntDesignStyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: "Poppins",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: "Poppins",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiCheckbox-root svg": {
      width: 10,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  },
  "css-1fajery-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
    justifyContent: "center!important",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 600,
  width: "97%",

  marginRight: "30px",
  marginTop: "50px!important",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 200,
      margin: theme.spacing(2),
    },
  },
}));

type GridDataType = "Employee" | "Commodity";
type GridDataThemeOption = "default" | "ant";
function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

interface GridConfigOptions {
  size: number;
  type: GridDataType;
  pagesize: number;
  theme: GridDataThemeOption;
}

interface GridToolbarContainerProps {
  onApply: (options: GridConfigOptions) => void;
  size: number;
  type: GridDataType;
  theme: GridDataThemeOption;
}
const INITIAL_GROUPING_COLUMN_MODEL = ["university"];

const useKeepGroupingColumnsHidden = (
  apiRef: React.MutableRefObject<GridApi>,
  columns: GridColumns,
  initialModel: GridRowGroupingModel,
  leafField?: string
) => {
  const prevModel = React.useRef(initialModel);

  React.useEffect(() => {
    apiRef.current.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };
        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return React.useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};

let randomCol: any = [
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

const columns = [
  // { field: '_id', headerName: 'ID', width: 300 },
  {
    field: "new_tab",
    headerName: "New Tab",
    width: 180,
    renderCell: (params: any) => <Button>Open in new tab</Button>,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "first_name",
    headerName: "Name",
    width: 140,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "email",
    headerName: "Email",
    width: 140,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 140,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "university",
    headerName: "University",
    width: 150,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        <Chip
          label={params.value}
          color={randomCol[params?.row?.random]}
          style={{
            backgroundColor: `${randomCol[params?.row?.random]}`,
            color: "#ffff",
            fontSize: "14px",
            height: "25px",
          }}
        />
      </Stack>
    ),
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",
    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "gpa",
    headerName: "GPA",

    width: 120,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "sat",
    headerName: "SAT",

    width: 90,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "act",
    headerName: "ACT",

    width: 90,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },

  {
    field: "gmat",
    headerName: "GMAT",

    width: 90,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "company",
    headerName: "Company",

    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
  },
  {
    field: "affinity_group",
    headerName: "Affinity Group",
    width: 290,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        {params?.row?.affinity_group?.map((affinity: string, index: number) => (
          <>
            <AffinityChips affinity={affinity} />
            <br></br>
          </>
        ))}
      </Stack>
    ),
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator: any) => operator.value === "equals"
    ),
    // editable: true,
  },
  {
    field: "resume",
    headerName: "Resume",
    width: 200,
    renderCell: (params: any) => (
      <Button style={{ padding: "4px 10px", fontSize: "11px" }}>
        View Resume
      </Button>
    ),
  },
  {
    field: "time",
    headerName: "Joined",
    width: 200,
  },
  // {
  //   field: "industry",
  //   headerName: "Industry",

  //   width: 200,
  // },
];

const columnsFinnect = [
  // { field: '_id', headerName: 'ID', width: 300 },
  {
    field: "new_tab",
    headerName: "New Tab",
    width: 180,
    renderCell: (params: any) => <Button>Open in new tab</Button>,
  },
  {
    field: "first_name",
    headerName: "Name",
    width: 140,
  },
  {
    field: "email",
    headerName: "Email",
    width: 140,
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 140,
  },
  {
    field: "university",
    headerName: "University",
    width: 150,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        <Chip
          label={params.value}
          color={randomCol[params?.row?.random]}
          style={{
            backgroundColor: `${randomCol[params?.row?.random]}`,
            color: "#ffff",
            fontSize: "14px",
            height: "25px",
          }}
        />
      </Stack>
    ),
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",
    width: 200,
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    // editable: true,
  },
  {
    field: "gpa",
    headerName: "GPA",

    width: 120,
    // editable: true,
  },
  {
    field: "sat",
    headerName: "SAT",

    width: 90,
    // editable: true,
  },
  {
    field: "act",
    headerName: "ACT",

    width: 90,
    // editable: true,
  },

  {
    field: "gmat",
    headerName: "GMAT",

    width: 90,
    // editable: true,
  },
  {
    field: "company",
    headerName: "Company",

    width: 200,
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
  },
  {
    field: "affinity_group",
    headerName: "Affinity Group",
    width: 290,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        {params?.row?.affinity_group?.map((affinity: string, index: number) => (
          <>
            <AffinityChips affinity={affinity} />
            <br></br>
          </>
        ))}
      </Stack>
    ),
    // editable: true,
  },
  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    // editable: true,
  },
  {
    field: "resume",
    headerName: "Resume",
    width: 200,
    renderCell: (params: any) => (
      <Button style={{ padding: "4px 10px", fontSize: "11px" }}>
        View Resume
      </Button>
    ),
  },
  {
    field: "time",
    headerName: "Joined",
    width: 200,
  },
  {
    field: "objectives",
    headerName: "Networking Objectives",
    width: 300,
  },
  {
    field: "industries",
    headerName: "Industry Choices",
    width: 300,
  },
  {
    field: "careers",
    headerName: "Careers Choices",
    width: 300,
  },
  {
    field: "personal_interests",
    headerName: "Personal Interests",
    width: 300,
  },
];

interface UserFiltersObjLayout {
  [key: string]: any;
}

const userFilterHeadersMap: UserFiltersObjLayout = {
  first_name: "first_name",
  email: "email",
  mobile: "mobile",
  university: "education.name",
  graduation_year: "education.graduation_year",
  current_level: "education_level",
  gpa: "gpa",
  sat: "sat",
  act: "act",
  gmat: "gmat",
  company: "work_experiences[0].company",
  title: "work_experiences[0].title",
  city: "work_experiences[0].city",
  affinity_group: "affinity_groups[0]",
  partner_club: "education[0].partner_club",
  resume: "resume",
};

const UserTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const { userInfo } = useSelector((state: any) => state.userDetails);
  const { usersList, loading } = useSelector((state: any) => state.userList);
  const { usersFinnectList, loading: loadingFinnect } = useSelector(
    (state: any) => state.userFinnectList
  );
  const { filteredUserList, isfilteredUserListLoading } = useSelector(
    (state: any) => state.filteredUserList
  );
  const { searchedUserList, isSearchedUserListLoading } = useSelector(
    (state: any) => state.searchedUserList
  );
  const { searchedFinnectUserList, isSearchedFinnectUserListLoading } =
    useSelector((state: any) => state.searchedFinnectUserList);

  const [searchBarQuery, setSearchBarQuery] = useState("");
  const [pageSize, setPageSize] = useState<number>(100);
  const [userType, setUserType] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowCountState, setRowCountState] = React.useState(
    userType === 0
      ? usersList?.total_count
      : userType === 1
      ? usersFinnectList?.total_count
      : 0
  );

  const [filteredUsersRowCount, setFilteredUsersRowCount] = useState(0);
  const [searchedUsersRowCount, setSearchedUsersRowCount] = useState(0);
  const [searchedFinnectUsersRowCount, setSearchedFinnectUsersRowCount] =
    useState(0);

  const [isAntDesign, setIsAntDesign] = useState<boolean>(false);
  // const [type, setType] = useState<GridDataType>("Commodity");
  // const [finalClickInfo, setFinalClickInfo] = useState(null);
  // const [jobId, setJobId] = useState();
  const [OnClickGroupColumn, setOnClickGroupColumn] = useState("university");
  // const [groupColumn, setGroupColumn] = useState<string>();
  const [randomGroup, setRandomGroup] = useState(false);
  // const [tableView, settableView] = useState();
  const [rows, setRow] = useState([]);
  const [cols, setCols] = useState(columns);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [userFilters, setUserFilters] = useState<any[]>([]);
  // const [size, setSize] = useState(100);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  // const [numPages, setNumPages] = useState(null);
  // const [subgroupColumn, setSubGroupColumn] = useState();
  // const [checked, setChecked] = useState<any[]>([]);
  // const [checkOpen, setCheckOpen] = useState(false);
  const [expandTable, setExpandTable] = useState(false);

  const [rowGroupingModel, setRowGroupingModel] = useState(
    INITIAL_GROUPING_COLUMN_MODEL
  );

  const DataGridComponent = isAntDesign
    ? AntDesignStyledDataGridPro
    : DataGridPro;

  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")!)
    : null;

  const data = {
    ...cols,
    ...rows,
  };

  const searchedUsersTableData = {
    ...cols,
    ...rows,
  };

  const searchedFinnectUsersTableData = {
    ...cols,
    ...rows,
  };

  const filteredUsersTableData = {
    ...cols,
    ...rows,
  };

  const [pagination, setPagination] = React.useState<GridPaginationSettings>({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  const getActiveTheme = () => {
    return isAntDesign ? "ant" : "default";
  };

  const selectUserTypeChangeHandler = (e: any) => {
    setUserType(Number(e.target.value));
    setSearchBarQuery("");
  };

  const allUsersBulkDownloadHandler = () => {
    dispatch(bulkDownloadAllUsers());
  };

  const allFinnectUsersBulkDownloadHandler = () => {
    dispatch(bulkDownloadAllFinnectUsers());
  };

  const filterChangeHandler = useCallback((filterModel) => {
    let filters = filterModel.items;
    let doesAtLeastOneFilterHasValue = false;
    for (let filter of filters) {
      if (filter.value) {
        doesAtLeastOneFilterHasValue = true;
        break;
      }
    }
    if (doesAtLeastOneFilterHasValue) setIsFilterOn(true);
    else setIsFilterOn(false);
    setUserFilters([...filterModel.items]);
  }, []);

  const searchBarQueryChangeHandler = debounce((val: any) => {
    setSearchBarQuery(val);
  });

  const setFilterHeaders = (userFilters: any[]) => {
    let filterHeaders = {};
    for (let userFilter of userFilters) {
      filterHeaders = {
        ...filterHeaders,
        [userFilterHeadersMap[userFilter.columnField]]: userFilter.value,
      };
    }
    return filterHeaders;
  };

  const getAllCurrentPageUsersListRows = (usersList: any) => {
    const usersListRows: any[] = [];
    usersList?.records.forEach((userData: any, index: number) => {
      const Current_level = () => {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        if (
          (userData?.education &&
            userData?.education[0]?.graduation_year < year) ||
          (userData?.education[0]?.graduation_year === year && month > 7)
        ) {
          if (userData?.education[0]?.education_type === 0)
            return "Professional Undergraduate";
          else return "Professional MBA";
        } else if (userData?.education[0]?.education_type === 0)
          return "Undergraduate";
        else return "MBA";
      };
      const Time = moment.unix(userData?.timestamp).format("LLL");
      const rndInt = Math.floor(Math.random() * 5) + 1;

      const city1 = userData?.work_experiences
        ? userData?.work_experiences[0]?.city
        : "-";
      const company = userData?.work_experiences
        ? userData?.work_experiences[0]?.company
        : "-";
      const industry = userData?.work_experiences
        ? userData?.work_experiences[0]?.industry
        : "-";
      const title = userData?.work_experiences
        ? userData?.work_experiences[0]?.title
        : "-";

      const university = userData?.education
        ? userData?.education[0]?.name
        : "-";

      const partnerClub = userData?.education
        ? userData?.education[0]?.partner_club
        : "-";

      const graduationYear = userData?.education
        ? userData?.education[0]?.graduation_year
        : "-";

      usersListRows.push({
        _id: userData?._id,
        index: index,
        first_name: userData?.first_name + " " + userData?.last_name,
        mobile: userData?.mobile,
        act: userData?.act === 0 ? "-" : userData?.act,
        gpa: userData?.gpa === 0 ? "-" : userData?.gpa,
        gmat: userData?.gmat === 0 ? "-" : userData?.gmat,
        sat: userData?.sat === 0 ? "-" : userData?.sat,
        email: userData?.email,
        job_title: userData?.job_title,
        university: university,
        partner_club: partnerClub,
        affinity_group: userData?.affinity_groups,
        graduation_year: graduationYear,
        resume: userData?.resume,
        current_level: Current_level(),
        random: rndInt,
        city: city1,
        company: company,
        industry: industry,
        title: title,
        time: Time,
      });
    });
    return usersListRows;
  };

  const getAllCurrentPageFinnectUsersListRows = (finnectUsersList: any) => {
    const finnectUsersListRows: any[] = [];
    finnectUsersList?.records.forEach((finnectUserData: any, index: number) => {
      const endDate = finnectUserData?.work_experiences[0]?.end_date
        ? moment(finnectUserData?.work_experiences[0].end_date).format("ll")
        : "-";
      const startDate = moment(
        finnectUserData?.work_experiences[0]?.start_date
      ).format("ll");

      const Current_level = () => {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        if (
          finnectUserData?.education === null ||
          finnectUserData?.education?.length === 0
        ) {
          return "-";
        } else {
          if (
            (finnectUserData?.education &&
              finnectUserData?.education[0]?.graduation_year < year) ||
            (finnectUserData?.education[0]?.graduation_year === year &&
              month > 7)
          ) {
            if (
              finnectUserData?.education &&
              finnectUserData?.education[0]?.education_type === 0
            )
              return "Professional Undergraduate";
            else return "Professional MBA";
          } else if (
            finnectUserData?.education &&
            finnectUserData?.education[0]?.education_type === 0
          )
            return "Undergraduate";
          else return "MBA";
        }
      };

      const Time = moment.unix(finnectUserData?.timestamp).format("LLL");
      const rndInt = Math.floor(Math.random() * 5) + 1;

      const city1 = finnectUserData?.work_experiences
        ? finnectUserData?.work_experiences[0]?.city
        : "-";
      const company = finnectUserData?.work_experiences
        ? finnectUserData?.work_experiences[0]?.company
        : "-";

      const title = finnectUserData?.work_experiences
        ? finnectUserData?.work_experiences[0]?.title
        : "-";

      const university = finnectUserData?.education
        ? finnectUserData?.education[0]?.name
        : "-";

      const partnerClub = finnectUserData?.education
        ? finnectUserData?.education[0]?.partner_club
        : "-";

      const graduationYear = finnectUserData?.education
        ? finnectUserData?.education[0]?.graduation_year
        : "-";

      const objectives = finnectUserData?.preferences?.objectives;

      const industry = finnectUserData?.preferences?.preferred_industries;

      const career = finnectUserData?.preferences?.preferred_careers;

      const personal_interests = finnectUserData?.preferences?.interests;
      finnectUsersListRows.push({
        _id: finnectUserData?._id,
        index: index,
        first_name:
          finnectUserData?.first_name + " " + finnectUserData?.last_name,
        mobile: finnectUserData?.mobile,
        act: finnectUserData?.act === 0 ? "-" : finnectUserData?.act,
        gpa: finnectUserData?.gpa === 0 ? "-" : finnectUserData?.gpa,
        gmat: finnectUserData?.gmat === 0 ? "-" : finnectUserData?.gmat,
        sat: finnectUserData?.sat === 0 ? "-" : finnectUserData?.sat,
        email: finnectUserData?.email,
        job_title: finnectUserData?.job_title,
        university: university,
        partner_club: partnerClub,
        affinity_group: finnectUserData?.affinity_groups,
        graduation_year: graduationYear,
        resume: finnectUserData?.resume,
        current_level: Current_level(),
        random: rndInt,
        city: city1,
        company: company,
        title: title,
        time: Time,
        objectives: objectives,
        industries: industry,
        careers: career,
        personal_interests: personal_interests,
      });
    });
    return finnectUsersListRows;
  };

  const getRowCount = () => {
    if (userType === 0) {
      if (!searchBarQuery && !isFilterOn) return rowCountState;
      if (searchBarQuery) return searchedUsersRowCount;
      if (isFilterOn) return filteredUsersRowCount;
    }
    if (userType === 1) {
      if (!searchBarQuery) return rowCountState;
      if (searchBarQuery) return searchedFinnectUsersRowCount;
    }
    return rowCountState;
  };

  useEffect(() => {
    if (!searchBarQuery && !isFilterOn) {
      if (userType === 0) {
        dispatch(getUserList({ skip: page * pageSize, limit: pageSize }));
        setCols(columns);
      } else {
        dispatch(
          getFinnectUserList({ skip: page * pageSize, limit: pageSize })
        );
        setCols(columnsFinnect);
      }
    }
  }, [userType, pageSize, page, searchBarQuery, isFilterOn]);

  useEffect(() => {
    if (userType === 0 && isFilterOn) {
      const filterHeaders = setFilterHeaders(userFilters);
      dispatch(
        getFilteredUserList({
          limit: rowCountState,
          filterHeaders: { ...filterHeaders },
        })
      );
      setCols(columns);
    }
  }, [userType, pageSize, isFilterOn, userFilters, rowCountState]);

  useEffect(() => {
    if (searchBarQuery) {
      if (userType === 0) {
        dispatch(
          getSearchedUserList({ query: searchBarQuery, limit: rowCountState })
        );
        setCols(columns);
      }
      if (userType === 1) {
        dispatch(
          getSearchedFinnectUserList({
            query: searchBarQuery,
            limit: rowCountState,
          })
        );
        // );
      }
    }
  }, [searchBarQuery, userType, rowCountState]);

  useEffect(() => {
    if (!searchBarQuery) {
      if (userType === 0 && !loading) {
        const data1: any = getAllCurrentPageUsersListRows(usersList);
        setRow(data1);
      }
      if (userType === 1 && !loadingFinnect) {
        let data1: any =
          getAllCurrentPageFinnectUsersListRows(usersFinnectList);
        setRow(data1);
      }
    }
  }, [
    usersList,
    usersFinnectList,
    OnClickGroupColumn,
    userType,
    searchBarQuery,
    loading,
    loadingFinnect,
  ]);

  useEffect(() => {
    if (searchBarQuery) {
      if (userType === 0) {
        if (!isSearchedUserListLoading) {
          if (searchedUserList?.count === 0) {
            setRow([]);
            return;
          }
          let searchedUserData: any =
            getAllCurrentPageUsersListRows(searchedUserList);
          setRow(searchedUserData);
        }
      }
      if (userType === 1) {
        if (!isSearchedFinnectUserListLoading) {
          if (searchedFinnectUserList?.count === 0) {
            setRow([]);
            return;
          }
          let searchedFinnectUserData: any =
            getAllCurrentPageFinnectUsersListRows(searchedFinnectUserList);
          setRow(searchedFinnectUserData);
        }
      }
    }
  }, [
    isSearchedUserListLoading,
    isSearchedFinnectUserListLoading,
    searchBarQuery,
    searchedUserList,
    searchedFinnectUserList,
    userType,
  ]);
  useEffect(() => {
    if (userType === 0 && isFilterOn) {
      if (!isfilteredUserListLoading) {
        if (filteredUserList?.count === 0) {
          setRow([]);
          return;
        }
        let filteredUserData: any =
          getAllCurrentPageUsersListRows(filteredUserList);
        setRow(filteredUserData);
      }
    }
    if (userType === 0 && !isFilterOn) {
      const data1: any = getAllCurrentPageUsersListRows(usersList);
      setRow(data1);
    }
  }, [
    isfilteredUserListLoading,
    isFilterOn,
    filteredUserList,
    userType,
    usersList,
  ]);

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      userType === 0 && usersList?.total_count !== undefined
        ? usersList?.total_count
        : userType === 1 && usersFinnectList?.total_count !== undefined
        ? usersFinnectList?.total_count
        : prevRowCountState
    );
  }, [usersList, usersFinnectList, rowCountState, userType]);

  useEffect(() => {
    if (userType === 0) {
      setSearchedUsersRowCount(searchedUserList?.count);
    }
    if (userType === 1) {
      setSearchedFinnectUsersRowCount(searchedFinnectUserList?.count);
    }
  }, [userType, searchedUserList, searchedFinnectUserList]);

  useEffect(() => {
    if (userType === 0) {
      setFilteredUsersRowCount(filteredUserList?.count);
    }
  }, [userType, filteredUserList]);

  const cellClickHandler = (params: any) => {
    if (params.field === "first_name") {
      navigate(`/user/${params.id}/edit`);
    }
    if (params.field === "new_tab") {
      window.open(`/user/${params.id}/edit`, "_blank");
    }
  };

  return (
    <div className="joblist">
      <div className="heading-button">
        <h1 className="head"> User list</h1>
        <FormControl sx={{ m: 1, maxWidth: 140 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Type of User
          </InputLabel>
          <Select
            value={userType}
            onChange={(e) => selectUserTypeChangeHandler(e)}
            autoWidth
            label="Type of User"
            style={{
              overflow: "hidden",
              height: "45px",
              color: "#7f00bb",
              background: "rgba(127, 0, 187, 0.08)",
              fontWeight: "500",
            }}
          >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>Finnect Users</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",

          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        {/*  search bar */}
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <TextField
            className="search"
            variant="standard"
            // value={searchBarQuery}
            onChange={(e) => searchBarQueryChangeHandler(e.target.value)}
            placeholder="Search for candidate"
            InputProps={{
              disableUnderline: true,
              startAdornment: <SearchIcon style={{ color: "#999999" }} />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  // style={{ visibility: searchText ? "visible" : "hidden" }}
                  onClick={() => searchBarQueryChangeHandler("")}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
        </Box>

        <FormControl sx={{ m: 1 }} className="formControal">
          <InputLabel id="demo-simple-select-autowidth-label">View</InputLabel>
          <Select
            defaultValue={1}
            // onChange={handleViewChange}
            autoWidth
            label="View"
            className="select"
          >
            <MenuItem value={1}>List view</MenuItem>
            <MenuItem value={2}>Group View</MenuItem>
          </Select>
        </FormControl>

        <div className="group">
          {randomGroup && (
            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                Group By
              </InputLabel>
              <Select
                // onChange={handleGroupColumnChange}
                autoWidth
                defaultValue={"university"}
                label="Group By"
                className="select"
              >
                {columnsFinnect.map((columns: any, index: number) => (
                  <MenuItem value={columns.field !== "resume" && columns.field}>
                    {columns.field !== "resume" && columns.headerName}
                  </MenuItem>
                ))}
                {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
              </Select>
            </FormControl>
          )}
          {randomGroup && (
            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                Group By 2
              </InputLabel>
              <Select
                // onChange={handleSubGroupColumnChange}
                autoWidth
                label="Group By 2"
                defaultValue={0}
                className="select"
              >
                <MenuItem value="0">None</MenuItem>
                {columnsFinnect.map((columns: any, index: number) => (
                  <MenuItem value={columns.field !== "resume" && columns.field}>
                    {columns.field !== "resume" && columns.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>

        <button
          // onClick={handleapplyChange}
          className="apply-btn"
          style={{
            background: "#7f00bb",
            color: "white",
            outline: "none",
            padding: "3px 15px",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          Apply
        </button>

        <button
          onClick={() => setExpandTable(true)}
          style={{
            background: "#7f00bb",
            color: "white",
            outline: "none",
            fontSize: "20px",
            height: "40px",
            padding: "3px 30px",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Expand
        </button>
      </div>
      {userType === 0 && (
        <div className="job-button">
          {/* {jobType && ( */}
          <a
            href={`${BASE}/api/v1/exports/users?access_token=${token.access_token}`}
            target="_blank"
          >
            <button
              style={{
                background: "rgba(127, 0, 187, 0.08)",
                color: "#7f00bb",
                outline: "none",
                fontSize: "16px",

                padding: "3px 15px",
                border: "none",
                borderRadius: "20px",
                cursor: "pointer",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
              onClick={allUsersBulkDownloadHandler}
            >
              <FileDownloadOutlined
                style={{ fontSize: "22px", marginRight: "3px" }}
              />
              EXPORT ALL USERS
            </button>
          </a>
          {/* )} */}
        </div>
      )}

      {userType === 1 && (
        <div className="job-button">
          {/* {jobType && ( */}
          <a
            href={`${BASE}/api/v1/exports/finnect_users?access_token=${token.access_token}`}
            target="_blank"
          >
            <button
              style={{
                background: "rgba(127, 0, 187, 0.08)",
                color: "#7f00bb",
                outline: "none",
                fontSize: "16px",

                padding: "3px 15px",
                border: "none",
                borderRadius: "20px",
                cursor: "pointer",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
              onClick={allFinnectUsersBulkDownloadHandler}
            >
              <FileDownloadOutlined
                style={{ fontSize: "22px", marginRight: "3px" }}
              />
              EXPORT ALL FINNECT USERS
            </button>
          </a>
          {/* )} */}
        </div>
      )}

      <div className="body">
        {!toggleOpen && (
          <StyledBox>
            <DataGridComponent
              page={page}
              pageSize={pageSize}
              onPageChange={(newPage: any) => setPage(newPage)}
              onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
              rowsPerPageOptions={[50, 100, 200, 250]}
              pagination
              paginationMode="server"
              columns={cols}
              getRowId={(row: any) => row._id}
              rows={rows}
              rowCount={getRowCount()}
              {...(userType === 0 && !searchBarQuery && !isFilterOn && data)}
              {...(userType === 0 && searchBarQuery && searchedUsersTableData)}
              {...(userType === 0 && isFilterOn && filteredUsersTableData)}
              {...(userType === 1 && !searchBarQuery && data)}
              {...(userType === 1 &&
                searchBarQuery &&
                searchedFinnectUsersTableData)}
              components={{
                Toolbar: GridToolbar,
              }}
              loading={
                loading ||
                loadingFinnect ||
                isSearchedUserListLoading ||
                isfilteredUserListLoading ||
                isSearchedFinnectUserListLoading
              }
              checkboxSelection
              disableSelectionOnClick
              rowThreshold={0}
              onCellClick={cellClickHandler}
              filterMode="server"
              onFilterModelChange={filterChangeHandler}
            />
          </StyledBox>
        )}
        {toggleOpen && (
          <div
            style={{
              height: 600,
              width: "97%",
              marginRight: "30px",
              marginTop: "100px",
            }}
          >
            <DataGridPro
              getRowId={(row) => row._id}
              rows={rows}
              {...data}
              components={{
                Toolbar: GridToolbar,
              }}
              apiRef={apiRef}
              columns={columnsFinnect}
              // onCellClick={handleOnCellClickGroup}
              // rowGroupingModel={rowGroupingModel}
              experimentalFeatures={{
                rowGrouping: true,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setResume("");
        }}
      >
        <Box sx={modal}>
          <Document
            file={`${resume}&access_token=${token?.access_token}`}
            // onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Modal>

      <FullScreenDialog open={expandTable} setOpen={setExpandTable}>
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",

              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            {/*  search bar */}
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                // value={searchText}
                // onChange={SarchHandler}
                placeholder="Search for candidate"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      // style={{ visibility: searchText ? "visible" : "hidden" }}
                      // onClick={clearSearch}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Box>

            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                View
              </InputLabel>
              <Select
                defaultValue={1}
                // onChange={handleViewChange}
                autoWidth
                label="View"
                className="select"
              >
                <MenuItem value={1}>List view</MenuItem>
                <MenuItem value={2}>Group View</MenuItem>
              </Select>
            </FormControl>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    // onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={"university"}
                    label="Group By"
                    className="select"
                  >
                    {columnsFinnect.map((columns: any, index: number) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    // onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columnsFinnect.map((columns: any, index: number) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              // onClick={handleapplyChange}
              className="apply-btn"
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                padding: "3px 15px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Apply
            </button>

            <button
              onClick={() => setExpandTable(false)}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Collapse
            </button>
          </div>

          <div className="body">
            {!toggleOpen && (
              <StyledBox style={{ width: "100%" }}>
                <DataGridComponent
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[50, 100, 200, 250]}
                  pagination
                  paginationMode="server"
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  rowCount={rowCountState}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading || loadingFinnect}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={cellClickHandler}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: 600,
                  marginRight: "30px",
                  marginTop: "100px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columnsFinnect}
                  // onCellClick={handleOnCellClickGroup}
                  // rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                />
              </div>
            )}
          </div>
        </>
      </FullScreenDialog>
    </div>
  );
};

export default LayoutsSide(UserTable);

const modal = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  backgroundColor: "white",
  width: "fit-content",
  height: "1240px",
  overflowY: "scroll",
  minWidth: "500px",
  maxWidth: "874px",
  maxHeight: "90vh",
  borderRadius: "10px",
  marginTop: "50px",
};
