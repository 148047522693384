import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_LOGOUT,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  USER_FORGET_MAIL_REQUEST,
  USER_FORGET_MAIL_SUCCESS,
  USER_FORGET_MAIL_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_FINNECT_LIST_REQUEST,
  USER_FINNECT_LIST_SUCCESS,
  USER_FINNECT_LIST_FAIL,
  USER_LIST_FILTERED_REQUEST,
  USER_LIST_FILTERED_SUCCESS,
  USER_LIST_FILTERED_FAIL,
  USER_LIST_SEARCHED_REQUEST,
  USER_LIST_SEARCHED_SUCCESS,
  USER_LIST_SEARCHED_FAIL,
  FINNECT_USER_LIST_SEARCHED_REQUEST,
  FINNECT_USER_LIST_SEARCHED_SUCCESS,
  FINNECT_USER_LIST_SEARCHED_FAIL,
  ADMIN_IMAGE_UPLOAD_REQUEST,
  ADMIN_IMAGE_UPLOAD_SUCCESS,
  ADMIN_IMAGE_UPLOAD_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_BANNER_UPLOAD_REQUEST,
  USER_BANNER_UPLOAD_SUCCESS,
  USER_BANNER_UPLOAD_FAIL,
  USER_PROFILE_IMAGE_UPLOAD_REQUEST,
  USER_PROFILE_IMAGE_UPLOAD_SUCCESS,
  USER_PROFILE_IMAGE_UPLOAD_FAIL,
  BULK_DOWNLOAD_ALL_USERS_REQUEST,
  BULK_DOWNLOAD_ALL_USERS_SUCCESS,
  BULK_DOWNLOAD_ALL_USERS_FAIL,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL
} from "../Constants/userConstants";

export const userSignInReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };

    case USER_SIGNIN_SUCCESS:
      return {
        token: action.payload,
        loading: false,
        isAuthenticated: true,
      };

    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userDetailsReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case ADMIN_DETAILS_REQUEST:
      return { loading: true };

    case ADMIN_DETAILS_SUCCESS:
      return {
        userInfo: action.payload,
        loading: false,
        isAuthenticated: true,
      };

    case ADMIN_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userForgetMailReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_FORGET_MAIL_REQUEST:
      return { loading: true };

    case USER_FORGET_MAIL_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_FORGET_MAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userPasswordChangeReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { loading: true };

    case USER_PASSWORD_CHANGE_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userListReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };

    case USER_LIST_SUCCESS:
      return {
        usersList: action.payload,
        loading: false,
        error: false,
      };

    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userFinnectListReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_FINNECT_LIST_REQUEST:
      return { loading: true };

    case USER_FINNECT_LIST_SUCCESS:
      return {
        usersFinnectList: action.payload,
        loading: false,
        error: false,
      };

    case USER_FINNECT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const filteredUserListReducer = (state = {}, action: { type: string, payload: {} }) => {
  switch (action.type) {
    case USER_LIST_FILTERED_REQUEST:
      return { isfilteredUserListLoading: true };

    case USER_LIST_FILTERED_SUCCESS:
      return {
        filteredUserList: action.payload,
        isfilteredUserListLoading: false,
        error: false,
      };

    case USER_LIST_FILTERED_FAIL:
      return { isfilteredUserListLoading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
}

export const searchedUserListReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_LIST_SEARCHED_REQUEST:
      return { isSearchedUserListLoading: true };

    case USER_LIST_SEARCHED_SUCCESS:
      return {
        searchedUserList: action.payload,
        isSearchedUserListLoading: false,
        error: false,
      };

    case USER_LIST_SEARCHED_FAIL:
      return { isSearchedUserListLoading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const searchedFinnectUserListReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case FINNECT_USER_LIST_SEARCHED_REQUEST:
      return { isSearchedFinnectUserListLoading: true };

    case FINNECT_USER_LIST_SEARCHED_SUCCESS:
      return {
        searchedFinnectUserList: action.payload,
        isSearchedFinnectUserListLoading: false,
        error: false,
      };

    case FINNECT_USER_LIST_SEARCHED_FAIL:
      return { isSearchedFinnectUserListLoading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const adminImageUploadReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case ADMIN_IMAGE_UPLOAD_REQUEST:
      return { loading: true };

    case ADMIN_IMAGE_UPLOAD_SUCCESS:
      return {
        imageLink: action.payload,
        success: true,
        loading: false,
      };

    case ADMIN_IMAGE_UPLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userDetailsByIdReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };

    case USER_DETAILS_SUCCESS:
      return {
        user: action.payload,
        loading: false,
        success: true,
      };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userUpdateByIdReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };

    case USER_UPDATE_SUCCESS:
      return {
        user: action.payload,
        loading: false,
        success: true,
      };

    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userChangeProfileImageReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_PROFILE_IMAGE_UPLOAD_REQUEST:
      return { loading: true };

    case USER_PROFILE_IMAGE_UPLOAD_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_PROFILE_IMAGE_UPLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userBannerUpdateReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_BANNER_UPLOAD_REQUEST:
      return { loading: true };

    case USER_BANNER_UPLOAD_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_BANNER_UPLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bulkDownloadAllUsersReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BULK_DOWNLOAD_ALL_USERS_REQUEST:
      return { loading: true };

    case BULK_DOWNLOAD_ALL_USERS_SUCCESS:
      return {
        zipFile: action.payload,
        loading: false,
        error: false,
      };

    case BULK_DOWNLOAD_ALL_USERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const bulkDownloadAllFinnectUsersReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST:
      return { loading: true };

    case BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS:
      return {
        zipFile: action.payload,
        loading: false,
        error: false,
      };

    case BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};