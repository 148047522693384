import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";

import LayoutsSide from "../../components/Layout/LayoutsSide";
import {
  getFilterList,
  getTemplateList,
  postEmails,
} from "../../redux/Actions/emailActions";

const EmailSender = () => {
  const dispatch = useDispatch();

  const { templates } = useSelector((state: any) => state.emailTemplateList);
  const { filters } = useSelector((state: any) => state.emailFilterList);
  const { loading, success } = useSelector((state: any) => state.emailSend);

  const [err, setErr] = useState({ status: false, message: "" });
  const [callType, setCallType] = useState("email");
  const [mailData, setMailData] = useState({
    template_id: "",
    sender: "invitation@elevatecareernetwork.com",
    reply_to: "info@elevatecareernetwork.com",
    filter_sets: [],
    contents: "",
    subject: "",
    users: [] as object[],
  });

  useEffect(() => {
    dispatch(getTemplateList());
    dispatch(getFilterList());
  }, []);

  const callHandler = (e: any, newVal: string) => {
    setCallType(newVal);
  };

  const filterHandler = (e: any, value: any) => {
    setMailData({
      ...mailData,
      filter_sets: value,
    });
  };

  const changeHandler = (e: any, input: string) => {
    setMailData({
      ...mailData,
      [input]: e.target.value,
    });
  };

  const submitHandler = () => {
    if (mailData.template_id === "") {
      setErr({ status: true, message: "Please select template" });
    } else if (
      callType === "filters" &&
      (mailData.filter_sets.length === 0 || mailData.filter_sets === null)
    ) {
      setErr({ status: true, message: "Please select filters" });
    } else if (mailData.contents === "") {
      setErr({ status: true, message: "Please enter something in Body" });
    } else if (mailData.subject === "") {
      setErr({ status: true, message: "Please enter something in Subject" });
    } else {
      dispatch(postEmails(mailData));
    }
  };

  return (
    <div className="email-sender">
      <div className="head-text">
        <h1>Welcome!</h1>
        <h2>Send Emails to users...</h2>
      </div>

      <div className="all-fields">
        <div className="templates">
          <div className="row">
            <h2>Templates</h2>

            <Select
              value={mailData.template_id}
              onChange={(e) => changeHandler(e, "template_id")}
            >
              {templates?.records?.map((temp: any, index: number) => (
                <MenuItem value={temp.record.template_id}>
                  {temp.record.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className="type">
          <div className="row">
            <h2>Type</h2>

            <ToggleButtonGroup
              color="primary"
              value={callType}
              exclusive
              onChange={callHandler}
            >
              <ToggleButton value="email">Email</ToggleButton>
              <ToggleButton value="filters">Filters</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        {callType === "email" ? (
          <div className="emails">
            <div className="row">
              <h2>Email</h2>

              <Autocomplete
                multiple
                disableClearable
                freeSolo
                id="tags-outlined"
                // value={data.tags}
                // onChange={(e, newVal) => basicChangeHandler(newVal, "tags")}
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add emails here (Type and press Enter)"
                    placeholder="Emails"
                  />
                )}
              />
            </div>
          </div>
        ) : (
          <div className="filters">
            <div className="row">
              <h2>Filters</h2>

              <Autocomplete
                multiple
                disableClearable
                id="tags-outlined"
                value={mailData.filter_sets}
                onChange={filterHandler}
                options={filters?.records?.map((item: any) => item["_id"])}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add filters here (Type and press Enter)"
                    placeholder="Filters"
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="subject">
          <div className="row">
            <h2>Subject</h2>

            <TextField
              value={mailData.subject}
              onChange={(e) => changeHandler(e, "subject")}
            />
          </div>
        </div>

        <div className="body">
          <div className="row">
            <h2>Body</h2>

            <TextField
              value={mailData.contents}
              onChange={(e) => changeHandler(e, "contents")}
            />
          </div>
        </div>
      </div>

      <div className="submit">
        {err.status && <Alert severity="error">{err.message}</Alert>}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={submitHandler} variant="contained">
            Send
          </Button>
        )}
      </div>
    </div>
  );
};

export default LayoutsSide(EmailSender);
