const debounce = (func: any, timeout = 500) => {
  let timerID: any;
  return (...args: any[]) => {
    const context = this;
    if (timerID) clearTimeout(timerID);
    timerID = setTimeout(() => {
      func.apply(context, args);
    }, timeout);
  };
}

export default debounce;