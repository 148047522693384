import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getCandidatesDetails,
  getCandidatesList,
} from "../../redux/Actions/jobActions";

import CandidateDetailCard from "./CandidateDetailCard";
import CandidateScoreCard from "./CandidateScoreCard";

const useKeyPress = function (targetKey: any) {
  const [keyPressed, setKeyPressed] = useState(false);

  function leftHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const rightHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", leftHandler);
    window.addEventListener("keyup", rightHandler);

    return () => {
      window.removeEventListener("keydown", leftHandler);
      window.removeEventListener("keyup", rightHandler);
    };
  });

  return keyPressed;
};

const CandidateDetails: React.FC<{
  setIndex: any;
  index: number;
  maxLength: number;
}> = ({ setIndex, index, maxLength }) => {
  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  let applicationId = searchParams.get("applicationId");
  let ind = searchParams.get("index");
  let jobId = searchParams.get("jobId");

  useEffect(() => {
    if (index === -1) {
      setIndex(Number(ind));
    }
  }, []);

  useEffect(() => {
    if (candidateList === undefined) {
      dispatch(getCandidatesList(jobId));
    }
  }, []);

  const { candidateList } = useSelector((state: any) => state.jonCandidateList);
  const { candidateDetail, loading, error } = useSelector(
    (state: any) => state.candidateDetails
  );

  // console.log(candidateDetail)

  useEffect(() => {
    dispatch(getCandidatesDetails(applicationId));
  }, [applicationId]);

  useEffect(() => {
    if (leftPress) {
      if (index > 0) {
        setIndex((prev: number) => prev - 1);
      } else if (index === 0) {
        setIndex(maxLength - 1);
      }
    }
  }, [leftPress]);

  useEffect(() => {
    if (rightPress) {
      if (index < maxLength - 1) {
        setIndex((prev: number) => prev + 1);
      } else if (index < maxLength) {
        setIndex(0);
      }
    }
  }, [rightPress]);

  // console.log(candidateDetail)

  return (
    <div className="candidate-details">
      <CandidateDetailCard data={candidateDetail ? candidateDetail : {}} />
      <CandidateScoreCard
        data={candidateDetail ? candidateDetail : {}}
        index={index}
        maxLength={maxLength}
        setIndex={setIndex}
      />
    </div>
  );
};

export default CandidateDetails;
