import { Fragment } from "react";

import {
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import { jobFunction, cityNames } from "../../helpers/content/preferences";

const WorkFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const workHandler = (e: any, input: string, value?: any) => {
    if (input === "start_date" || input === "end_date") {
      setUserData({
        ...userData,
        work_experiences: {
          ...userData.work_experiences,
          [input]: e.toISOString(),
        },
      });
    } else if (input === "title" || input === "city") {
      setUserData({
        ...userData,
        work_experiences: {
          ...userData.work_experiences,
          [input]: value,
        },
      });
    } else {
      setUserData({
        ...userData,
        work_experiences: {
          ...userData.work_experiences,
          [input]: e.target.value,
        },
      });
    }
  };
  return (
    <div className="work_fields">
      {userData.work_experiences.map((work: any, index: number) => (
        <Fragment key={index}>
          <div className="row">
            <TextField
              required
              label="Firm Name"
              value={work.company}
              onChange={(e) => workHandler(e, "company")}
            />
            <Autocomplete
              disableClearable
              options={jobFunction}
              value={work.title}
              onChange={(e, value) => workHandler(e, "title", value)}
              renderInput={(params) => (
                <TextField {...params} label="Job Function" required />
              )}
            />
          </div>

          <div className="row">
            <FormControl fullWidth>
              <InputLabel required id="experience_type-label">
                Experience Type
              </InputLabel>
              <Select
                required
                labelId="experience_type-label"
                id="experience_type"
                value={work.experience_type}
                label="Experience Type"
                onChange={(e) => workHandler(e, "experience_type")}
              >
                <MenuItem value={1}>Internship</MenuItem>
                <MenuItem value={2}>Full Time</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel required id="experience_status-label">
                Experience Status
              </InputLabel>
              <Select
                required
                labelId="experience_status-label"
                id="experience_status"
                value={work.experience_status}
                label="Experience Status"
                onChange={(e) => workHandler(e, "experience_status")}
              >
                <MenuItem value={1}>Current</MenuItem>
                <MenuItem value={2}>Upcoming</MenuItem>
                <MenuItem value={3}>Past</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="row">
            <Autocomplete
              sx={{ width: "100%" }}
              disableClearable
              options={cityNames}
              value={work.city}
              onChange={(e, value) => workHandler(e, "city", value)}
              renderInput={(params) => (
                <TextField {...params} label="Location" required />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Start Date"
                views={["year", "month"]}
                inputFormat="MM/yyyy"
                maxDate={new Date("2030-01-01T00:00:00.000Z")}
                minDate={new Date("2000-01-01T00:00:00.000Z")}
                value={
                  new Date(
                    work?.start_date
                      ? work?.start_date
                      : "2000-01-01T00:00:00.000Z"
                  )
                }
                onChange={(e) => workHandler(e, "start_date")}
                renderInput={(params) => <TextField {...params} />}
                // className={classes.textfield}
              />
            </LocalizationProvider>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default WorkFields;
