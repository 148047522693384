import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/index.css";
import App from "./App";
import AppRoutes from "./routes/Routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { LicenseInfo } from '@mui/x-data-grid-pro';
// import theme from "./theme";
const key = process.env.REACT_APP_MUI_X_PRO_KEY ? process.env.REACT_APP_MUI_X_PRO_KEY : "";
LicenseInfo.setLicenseKey(
  key,
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    
      <AppRoutes />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
