import axios from "axios";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_LOGOUT,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  USER_FORGET_MAIL_REQUEST,
  USER_FORGET_MAIL_SUCCESS,
  USER_FORGET_MAIL_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_FINNECT_LIST_REQUEST,
  USER_FINNECT_LIST_SUCCESS,
  USER_FINNECT_LIST_FAIL,
  USER_LIST_FILTERED_REQUEST,
  USER_LIST_FILTERED_SUCCESS,
  USER_LIST_FILTERED_FAIL,
  USER_LIST_SEARCHED_REQUEST,
  USER_LIST_SEARCHED_SUCCESS,
  USER_LIST_SEARCHED_FAIL,
  FINNECT_USER_LIST_SEARCHED_REQUEST,
  FINNECT_USER_LIST_SEARCHED_SUCCESS,
  FINNECT_USER_LIST_SEARCHED_FAIL,
  ADMIN_IMAGE_UPLOAD_REQUEST,
  ADMIN_IMAGE_UPLOAD_SUCCESS,
  ADMIN_IMAGE_UPLOAD_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_BANNER_UPLOAD_REQUEST,
  USER_BANNER_UPLOAD_SUCCESS,
  USER_BANNER_UPLOAD_FAIL,
  USER_PROFILE_IMAGE_UPLOAD_REQUEST,
  USER_PROFILE_IMAGE_UPLOAD_SUCCESS,
  USER_PROFILE_IMAGE_UPLOAD_FAIL,
  BULK_DOWNLOAD_ALL_USERS_REQUEST,
  BULK_DOWNLOAD_ALL_USERS_SUCCESS,
  BULK_DOWNLOAD_ALL_USERS_FAIL,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS,
  BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL,
} from "../Constants/userConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const signIn = (user: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });

    const { data } = await axios.post(`${BASE}/api/v1/auth/signin/admin`, user);

    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    // Dispatch the success action
    localStorage.setItem("token", JSON.stringify(data));

    dispatch(userDetails());
  } catch (error: any) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload: error.response,
    });
  }
};

export const signOut = () => async (dispatch: any) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");

  dispatch({ type: USER_LOGOUT });
};

export const userDetails = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ADMIN_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/user/me`, config);

    // Dispatch the success action
    dispatch({
      type: ADMIN_DETAILS_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error: any) {
    dispatch({
      type: ADMIN_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const forgetMail = (email: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_FORGET_MAIL_REQUEST,
    });

    const { data } = await axios.post(
      `${BASE}/api/v1/user/request/passwordreset`,
      {
        email: email,
      }
    );

    // Dispatch the success action
    dispatch({
      type: USER_FORGET_MAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_FORGET_MAIL_FAIL,
      payload: error.response,
    });
  }
};

export const userPasswordUpdate = (passData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_PASSWORD_CHANGE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/user/update/password`,
      passData,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_PASSWORD_CHANGE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_PASSWORD_CHANGE_FAIL,
      payload: error.response,
    });
  }
};

export const getUserList = (uri: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const user = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/user?skip=${uri.skip}&limit=${uri.limit}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error,
    });
  }
};

export const getFinnectUserList = (uri: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_FINNECT_LIST_REQUEST,
    });

    const user = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/admin/users_finnect?skip=${uri.skip}&limit=${uri.limit}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_FINNECT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_FINNECT_LIST_FAIL,
      payload: error,
    });
  }
};

export const getFilteredUserList = (uri: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_LIST_FILTERED_REQUEST,
    });

    const user = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;
    const config = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        "api-filter": JSON.stringify(uri.filterHeaders)
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/user?limit=${uri.limit}&skip=0`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_LIST_FILTERED_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_LIST_FILTERED_FAIL,
      payload: error,
    });
  }
}

export const getSearchedUserList = (uri: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_LIST_SEARCHED_REQUEST,
    });

    const user = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/user?q=${uri.query}&skip=0&limit=${uri.limit}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_LIST_SEARCHED_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_LIST_SEARCHED_FAIL,
      payload: error,
    });
  }
}

export const getSearchedFinnectUserList = (uri: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: FINNECT_USER_LIST_SEARCHED_REQUEST,
    });

    const user = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    };

    const data = await axios.get(
      `${BASE}/api/v1/admin/users_finnect?q=${uri.query}&skip=0&limit=${uri.limit}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: FINNECT_USER_LIST_SEARCHED_SUCCESS,
      payload: data.data,
    });
  } catch (error: any) {
    dispatch({
      type: FINNECT_USER_LIST_SEARCHED_FAIL,
      payload: error,
    });
  }
}

export const imageUploadAdmin = (imgData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADMIN_IMAGE_UPLOAD_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        "content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/storage/admin/upload`,
      imgData,
      config
    );

    // Dispatch the success action
    dispatch({
      type: ADMIN_IMAGE_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: ADMIN_IMAGE_UPLOAD_FAIL,
      payload: error.response,
    });
  }
};

export const userById = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/user/full/${userId}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const updateUserById = (user: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(`${BASE}/api/v1/user/admin`, user, config);

    // Dispatch the success action
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: error,
    });
  }
};

export const updateProfileImage = (userImage: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_PROFILE_IMAGE_UPLOAD_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        "content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/storage/admin/profile/upload`,
      userImage,
      config
    );

    // Dispatch the success action

    dispatch(userDetails());

    dispatch({
      type: USER_PROFILE_IMAGE_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_PROFILE_IMAGE_UPLOAD_FAIL,
      payload: error.response,
    });
  }
};

export const updateBannerImage =
  (bannerImage: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: USER_BANNER_UPLOAD_REQUEST,
      });

      const token = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          "content-type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${BASE}/api/v1/storage/admin/banner/upload`,
        bannerImage,
        config
      );

      // Dispatch the success action

      dispatch(userDetails());

      dispatch({
        type: USER_BANNER_UPLOAD_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_BANNER_UPLOAD_FAIL,
        payload: error.response,
      });
    }
  };


export const bulkDownloadAllUsers = () => async (dispatch: any) => {
  try {
    dispatch({
      type: BULK_DOWNLOAD_ALL_USERS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const res = await axios.get(
      `${BASE}/api/v1/exports/users?access_token=${token.access_token}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: BULK_DOWNLOAD_ALL_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    dispatch({
      type: BULK_DOWNLOAD_ALL_USERS_FAIL,
      payload: error.message,
    });
  }
}
export const bulkDownloadAllFinnectUsers = () => async (dispatch: any) => {
  try {
    dispatch({
      type: BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const res = await axios.get(
      `${BASE}/api/v1/exports/finenct_users?access_token=${token.access_token}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error: any) {
    dispatch({
      type: BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL,
      payload: error.message,
    });
  }
}