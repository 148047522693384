import { useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";

const OverviewFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {

  const firmDetailsHandler = (content:any) => {
    update({
      ...data,
      firm_overview: content
    })
  }

  const experienceHandler = (content:any) => {
    update({
      ...data,
      experience: content
    })
  }

  return (
    <div className="overviews_fields">
      <div className="firm">
        <h3>Firm Details</h3>
        <Editor
          apiKey={process.env.REACT_APP_EDITOR_KEY}
          cloudChannel="5.10"
          value={data.firm_overview}
          onEditorChange={firmDetailsHandler}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Poppins,sans-serif; font-size:14px }",
          }}
        />
      </div>

      <div className="experience">
        <h3>Experience Details</h3>
        <Editor
          apiKey={process.env.REACT_APP_EDITOR_KEY}
          cloudChannel="5.10"
          value={data.experience}
          onEditorChange={experienceHandler}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Poppins,sans-serif; font-size:14px }",
          }}
        />
      </div>
    </div>
  );
};

export default OverviewFields;
