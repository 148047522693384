import React from "react";

import { TextField } from "@mui/material";

const SocialFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const socialHandler = (e: any, input: string, n: number) => {
    let tempData = { ...userData };
    tempData.socials[n][input] = e.target.value;
    setUserData(tempData);
  };

  console.log(userData.socials);
  return (
    <div className="social_fields">
      {userData.socials.map((soc: any, index: number) => (
        <div key={index} className="row">
          <TextField
            label="Name"
            value={soc.name}
            onChange={(e) => socialHandler(e, "name", index)}
          />
          <TextField
            label="Url"
            value={soc.url}
            onChange={(e) => socialHandler(e, "url", index)}
          />
        </div>
      ))}
    </div>
  );
};

export default SocialFields;
