import {
  EVENT_ATTENDEE_DETAIL_REQUEST,
  EVENT_ATTENDEE_DETAIL_SUCCESS,
  EVENT_ATTENDEE_DETAIL_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_DATA_REQUEST,
  EVENT_DATA_SUCCESS,
  EVENT_DATA_FAIL,
  EVENT_SUMMARY_REQUEST,
  EVENT_SUMMARY_SUCCESS,
  EVENT_SUMMARY_FAIL,
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_SUCCESS,
  EVENT_CREATE_FAIL,
  EVENT_DETAILS_REQUEST,
  EVENT_DETAILS_SUCCESS,
  EVENT_DETAILS_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  BULK_CERTIFICATE_ISSUE_REQUEST,
  BULK_CERTIFICATE_ISSUE_SUCCESS,
  BULK_CERTIFICATE_ISSUE_FAIL,
} from "../Constants/eventConstants";

export const eventAttendeeDetailReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_ATTENDEE_DETAIL_REQUEST:
      return { loading: true };

    case EVENT_ATTENDEE_DETAIL_SUCCESS:
      return {
        eventAttendee: action.payload,
        loading: false,
        error: false,
      };

    case EVENT_ATTENDEE_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { loading: true };

    case EVENT_LIST_SUCCESS:
      return {
        eventLists: action.payload,
        loading: false,
        error: false,
      };

    case EVENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventDataReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_DATA_REQUEST:
      return { loading: true };

    case EVENT_DATA_SUCCESS:
      return {
        events: action.payload,
        loading: false,
        error: null,
      };

    case EVENT_DATA_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventSummaryReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_SUMMARY_REQUEST:
      return { loading: true };

    case EVENT_SUMMARY_SUCCESS:
      return {
        eventSummary: action.payload,
        loading: false,
        error: null,
      };

    case EVENT_SUMMARY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventCreateReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_CREATE_REQUEST:
      return { loading: true };

    case EVENT_CREATE_SUCCESS:
      return {
        createdEvent: action.payload,
        loading: false,
        success: true,
        error: false,
      };

    case EVENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventDetailReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_DETAILS_REQUEST:
      return { loading: true };

    case EVENT_DETAILS_SUCCESS:
      return {
        event: action.payload,
        loading: false,
        error: false,
      };

    case EVENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventUpdateReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_UPDATE_REQUEST:
      return { loading: true };

    case EVENT_UPDATE_SUCCESS:
      return {
        updatedEvent: action.payload,
        success: true,
        loading: false,
        error: null,
      };

    case EVENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const certificateIssueReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BULK_CERTIFICATE_ISSUE_REQUEST:
      return { loading: true };

    case BULK_CERTIFICATE_ISSUE_SUCCESS:
      return {
        bullCertificate: action.payload,
        loading: false,
        error: null,
      };

    case BULK_CERTIFICATE_ISSUE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
