export const EMAIL_TEMPLATE_LIST_REQUEST = "EMAIL_TEMPLATE_LIST_REQUEST";
export const EMAIL_TEMPLATE_LIST_SUCCESS = "EMAIL_TEMPLATE_LIST_SUCCESS";
export const EMAIL_TEMPLATE_LIST_FAIL = "EMAIL_TEMPLATE_LIST_FAIL";

export const EMAIL_FILTER_LIST_REQUEST = "EMAIL_FILTER_LIST_REQUEST";
export const EMAIL_FILTER_LIST_SUCCESS = "EMAIL_FILTER_LIST_SUCCESS";
export const EMAIL_FILTER_LIST_FAIL = "EMAIL_FILTER_LIST_FAIL";

export const EMAIL_SEND_REQUEST = "EMAIL_SEND_REQUEST";
export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAIL = "EMAIL_SEND_FAIL";