export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const ADMIN_DETAILS_REQUEST = "ADMIN_DETAILS_REQUEST";
export const ADMIN_DETAILS_SUCCESS = "ADMIN_DETAILS_SUCCESS";
export const ADMIN_DETAILS_FAIL = "ADMIN_DETAILS_FAIL";

export const USER_FORGET_MAIL_REQUEST = "USER_FORGET_MAIL_REQUEST";
export const USER_FORGET_MAIL_SUCCESS = "USER_FORGET_MAIL_SUCCESS";
export const USER_FORGET_MAIL_FAIL = "USER_FORGET_MAIL_FAIL";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";


export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_FINNECT_LIST_REQUEST = "USER_FINNECT_LIST_REQUEST";
export const USER_FINNECT_LIST_SUCCESS = "USER_FINNECT_LIST_SUCCESS";
export const USER_FINNECT_LIST_FAIL = "USER_FINNECT_LIST_FAIL";

export const USER_LIST_FILTERED_REQUEST = "USER_LIST_FILTERED_REQUEST";
export const USER_LIST_FILTERED_SUCCESS = "USER_LIST_FILTERED_SUCCESS";
export const USER_LIST_FILTERED_FAIL = "USER_LIST_FILTERED_FAIL";

export const USER_LIST_SEARCHED_REQUEST = "USER_LIST_SEARCHED_REQUEST";
export const USER_LIST_SEARCHED_SUCCESS = "USER_LIST_SEARCHED_SUCCESS";
export const USER_LIST_SEARCHED_FAIL = "USER_LIST_SEARCHED_FAIL";

export const FINNECT_USER_LIST_SEARCHED_REQUEST = "FINNECT_USER_LIST_SEARCHED_REQUEST";
export const FINNECT_USER_LIST_SEARCHED_SUCCESS = "FINNECT_USER_LIST_SEARCHED_SUCCESS";
export const FINNECT_USER_LIST_SEARCHED_FAIL = "FINNECT_USER_LIST_SEARCHED_FAIL";

export const ADMIN_IMAGE_UPLOAD_REQUEST = "ADMIN_IMAGE_UPLOAD_REQUEST";
export const ADMIN_IMAGE_UPLOAD_SUCCESS = "ADMIN_IMAGE_UPLOAD_SUCCESS";
export const ADMIN_IMAGE_UPLOAD_FAIL = "ADMIN_IMAGE_UPLOAD_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_PROFILE_IMAGE_UPLOAD_REQUEST = "USER_PROFILE_IMAGE_UPLOAD_REQUEST";
export const USER_PROFILE_IMAGE_UPLOAD_SUCCESS = "USER_PROFILE_IMAGE_UPLOAD_SUCCESS";
export const USER_PROFILE_IMAGE_UPLOAD_FAIL = "USER_PROFILE_IMAGE_UPLOAD_FAIL";

export const USER_BANNER_UPLOAD_REQUEST = "USER_BANNER_UPLOAD_REQUEST";
export const USER_BANNER_UPLOAD_SUCCESS = "USER_BANNER_UPLOAD_SUCCESS";
export const USER_BANNER_UPLOAD_FAIL = "USER_BANNER_UPLOAD_FAIL";

export const BULK_DOWNLOAD_ALL_USERS_REQUEST = "BULK_DOWNLOAD_ALL_USERS_REQUEST";
export const BULK_DOWNLOAD_ALL_USERS_SUCCESS = "BULK_DOWNLOAD_ALL_USERS_SUCCESS";
export const BULK_DOWNLOAD_ALL_USERS_FAIL = "BULK_DOWNLOAD_ALL_USERS_FAIL";

export const BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST = "BULK_DOWNLOAD_ALL_FINNECT_USERS_REQUEST";
export const BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS = "BULK_DOWNLOAD_ALL_FINNECT_USERS_SUCCESS";
export const BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL = "BULK_DOWNLOAD_ALL_FINNECT_USERS_FAIL";