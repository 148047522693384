import { useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { MenuItem, Select, TextField, Autocomplete } from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";

const BasicFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {
  const basicChangeHandler = (e: any, input: string) => {
    if (input === "schedule_date") {
      update({
        ...data,
        [input]: e.toISOString(),
      });
    } else if (input === "organisations") {
      let tempData = {...data}
      tempData.organisations[0] = e.target.value
      update({
        ...tempData
      });
    } else if (input === "tags") {
      update({
        ...data,
        [input]: e,
      });
    } else {
      update({
        ...data,
        [input]: e.target.value,
      });
    }
  };

  const descriptionHandler = (content: any) => {
    update({
      ...data,
      description: content,
    });
  };

  const addressHandler = (e: any, input: string) => {
    if (input === "cities") {
      update({
        ...data,
        address: {
          ...data.address,
          cities: e,
        },
      });
    } else {
      update({
        ...data,
        address: {
          ...data.address,
          country: e.target.value,
        },
      });
    }
  };
  return (
    <div className="basic_fields">
      <div className="row">
        <Select
          value={data.job_type}
          onChange={(e) => basicChangeHandler(e, "job_type")}
        >
          <MenuItem value={0}>Full Time</MenuItem>
          <MenuItem value={1}>Internship</MenuItem>
        </Select>
        <TextField
          label="Job Title"
          value={data.job_title}
          onChange={(e) => basicChangeHandler(e, "job_title")}
        />
        <TextField
          label="Organisation"
          value={data.organisations.length > 0 ? data.organisations[0] : ""}
          onChange={(e) => basicChangeHandler(e, "organisations")}
        />
      </div>

      <div className="row">
        <Autocomplete
          multiple
          disableClearable
          freeSolo
          id="tags-outlined"
          value={data.address.cities}
          onChange={(e, newVal) => addressHandler(newVal, "cities")}
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Add cities here (Type and press Enter)"
              placeholder="Cities"
            />
          )}
        />
        <TextField
          label="Country"
          value={data.address.country}
          onChange={(e) => addressHandler(e, "country")}
        />
      </div>

      <Editor
        apiKey={process.env.REACT_APP_EDITOR_KEY}
        cloudChannel="5.10"
        value={data.description}
        onEditorChange={descriptionHandler}
        init={{
          height: 300,
          menubar: false,
          placeholder: "Enter role details here...",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount textpattern lists",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Poppins,sans-serif; font-size:14px }",
          textpattern_patterns: [
            { start: "*", end: "*", format: "italic" },
            { start: "**", end: "**", format: "bold" },
            { start: "#", format: "h1" },
            { start: "##", format: "h2" },
            { start: "###", format: "h3" },
            { start: "####", format: "h4" },
            { start: "#####", format: "h5" },
            { start: "######", format: "h6" },
            // The following text patterns require the `lists` plugin
            { start: "1. ", cmd: "InsertOrderedList" },
            { start: "* ", cmd: "InsertUnorderedList" },
            { start: "- ", cmd: "InsertUnorderedList" },
            {
              start: "1. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "decimal" },
            },
            {
              start: "1) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "decimal" },
            },
            {
              start: "a. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-alpha" },
            },
            {
              start: "a) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-alpha" },
            },
            {
              start: "i. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-roman" },
            },
            {
              start: "i) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-roman" },
            },
          ],
        }}
      />

      <div className="row">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            clearable
            label="Schedule Date"
            value={data.schedule_date !== "" ? data.schedule_date : null}
            onChange={(newValue: Date | null) =>
              basicChangeHandler(newValue, "schedule_date")
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          label="Start (Summer 2023)"
          value={data.start_date}
          onChange={(e) => basicChangeHandler(e, "start_date")}
        />
        <TextField
          label="Deadline (Rolling; Apply ASAP)"
          value={data.deadline}
          onChange={(e) => basicChangeHandler(e, "deadline")}
        />
      </div>

      <Autocomplete
        multiple
        disableClearable
        freeSolo
        id="tags-outlined"
        value={data.tags}
        onChange={(e, newVal) => basicChangeHandler(newVal, "tags")}
        options={["Growth Equity", "Private Credit", "Hedge Fund"]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add tags here (Type and press Enter)"
            placeholder="Tags"
          />
        )}
      />
    </div>
  );
};

export default BasicFields;
