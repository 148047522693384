import { useRef } from "react";

import { TextField, Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";

import { Editor } from "@tinymce/tinymce-react";

const BasicFields: React.FC<{ data: any; update: any }> = ({ data, update }) => {
  const basicChangeHandler = (e: any, input: string) => {
    if (input === "name") {
      update({
        ...data,
        [input]: e.target.value,
      });
    } else if (input === "tags") {
      update({
        ...data,
        [input]: e,
      });
    } else {
      update({
        ...data,
        [input]: e.toISOString(),
      });
    }
  };

  const descriptionHandler = (content: any) => {
    update({
      ...data,
      description: content,
    });
  };

  return (
    <div className="basic_fields">
      <TextField
        className="text-fields"
        label="Name"
        value={data.name}
        onChange={(e) => basicChangeHandler(e, "name")}
      />

      <Editor
        apiKey={process.env.REACT_APP_EDITOR_KEY}
        cloudChannel="5.10"
        value={data.description}
        onEditorChange={descriptionHandler}
        init={{
          height: 300,
          menubar: false,
          placeholder: "Enter description here...",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount textpattern lists",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style: "body { font-family:Poppins,sans-serif; font-size:14px }",
          textpattern_patterns: [
            { start: "*", end: "*", format: "italic" },
            { start: "**", end: "**", format: "bold" },
            { start: "#", format: "h1" },
            { start: "##", format: "h2" },
            { start: "###", format: "h3" },
            { start: "####", format: "h4" },
            { start: "#####", format: "h5" },
            { start: "######", format: "h6" },
            // The following text patterns require the `lists` plugin
            { start: "1. ", cmd: "InsertOrderedList" },
            { start: "* ", cmd: "InsertUnorderedList" },
            { start: "- ", cmd: "InsertUnorderedList" },
            {
              start: "1. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "decimal" },
            },
            {
              start: "1) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "decimal" },
            },
            {
              start: "a. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-alpha" },
            },
            {
              start: "a) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-alpha" },
            },
            {
              start: "i. ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-roman" },
            },
            {
              start: "i) ",
              cmd: "InsertOrderedList",
              value: { "list-style-type": "lower-roman" },
            },
          ],
        }}
      />

      <div className="dates">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            clearable
            label="Schedule Date"
            value={data.schedule_date !== "" ? data.schedule_date : new Date()}
            onChange={(newValue: Date | null) =>
              basicChangeHandler(newValue, "schedule_date")
            }
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="Start Date"
            value={data.start_date !== "" ? data.start_date : null}
            onChange={(newValue: Date | null) =>
              basicChangeHandler(newValue, "start_date")
            }
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            clearable
            label="End Date"
            value={data.end_date !== "" ? data.end_date : null}
            onChange={(newValue: Date | null) =>
              basicChangeHandler(newValue, "end_date")
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <Autocomplete
        multiple
        disableClearable
        freeSolo
        id="tags-outlined"
        value={data.tags}
        onChange={(e, newVal) => basicChangeHandler(newVal, "tags")}
        options={[
          "Firm Event",
          "University Event",
          "Private Equity",
          "Venture Capital",
          "Hedge Fund",
        ]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add tags here (Type and press Enter)"
            placeholder="Tags"
          />
        )}
      />
    </div>
  );
};

export default BasicFields;
