import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEventSummary,
  getEventList,
} from "../../redux/Actions/eventActions";
import { Bar, ResponsiveBar } from "@nivo/bar";
import PieChart from "../../components/EventDashboard/PieChart";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  CircularProgress,
} from "@mui/material";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const EventSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chooseGraph, setChooseGraph] = useState(2);
  const [id, setId] = useState("");
  const [selectNumber, setSelectNumber] = useState(20);
  const [viewName, setViewName] = useState("university");
  const [pg, setPg] = useState(1);
  const [hideViewBy, sethideViewBy] = useState(false);
  const key: any = [];
  const key1: any = [];
  const data: any = [];
  const data1: any = [];

  let mapData: any = [];
  // const id ="6229bd3754ca1317efb7ade2";
  const { eventLists, loading, error } = useSelector(
    (state: any) => state.eventList
  );
  useEffect(() => {
    dispatch(getEventList());
  }, []);

  // console.log(eventLists);

  const { eventSummary, loading: loadingSummary } = useSelector(
    (state: any) => state.eventSummary
  );
  useEffect(() => {
    dispatch(getEventSummary(`${id}?group=${viewName}`));
  }, [viewName, id]);

  let Total_Attendees = 0;
  let Total_University = 0;
  Total_Attendees = eventSummary?.total_count;
  Total_University = eventSummary?.count;
  // console.log("eventSummary", eventSummary);

  eventSummary?.records?.map((university: any) => key.push(university?._id));
  let total_University = key.length;
  function pushToAry(name: any, val: any, index: any) {
    var obj: any = {};
    obj["id"] = index;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["University"] = name;
    data.push(obj);
  }

  eventSummary?.records?.map((university: any, index: any) =>
    pushToAry(university._id, university.count, index)
  );

  let sorted_data = data.sort((a: any, b: any) => {
    return b.value - a.value;
  });

  sorted_data
    .slice((pg - 1) * selectNumber, pg * selectNumber)
    .map((university: any) => key1.push(university.University));
  const total_key = key1.length;

  function mapAry(name: any, val: any, index: any) {
    var obj: any = {};
    obj["id"] = index;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["University"] = name;
    obj[`${name}Color`] = "hsl(5, 70%, 50%)";
    mapData.push(obj);
  }

  sorted_data
    ?.slice((pg - 1) * selectNumber, pg * selectNumber)
    .map((university: any, index: any) =>
      mapAry(university.University, university.value, index)
    );

  const handleViewChange = (event: any) => {
    setViewName(event.target.value);
  };

  const handleTypeChange = (event: any) => {
    setChooseGraph(event.target.value);
    if (event.target.value === 2) {
      sethideViewBy(false);
    }
    if (event.target.value === 1) {
      sethideViewBy(true);
    }
  };
  const handleEventChange = (event: any) => {
    setId(event.target.value);
  };
  const handleTopChoose = (event: any) => {
    setSelectNumber(event.target.value);
    setPg(1);
  };
  const pageHandler = (e: any, page: any) => {
    setPg(page);
  };
  const size = window.screen.availWidth;

  return (
    <div className="event-summary">
      <div className="event-banner">
        <h1 className="dashboard-heading">Dashboard</h1>
        <div className="heading-prograss">
          <FormControl sx={{ m: 1, minWidth: 400 }}>
            <Select
              onChange={handleEventChange}
              defaultValue="0"
              autoWidth
              style={{
                color: "#7F00BB",
                background: "#F8F9FA",
                height: "40px",
                fontSize: "16px",
                fontWeight: "600",
                width: "400px",
                overflow: "hidden",
              }}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 500, maxWidth: 400 } },
              }}
            >
              <MenuItem value="0" disabled>
                Select the Event
              </MenuItem>
              {eventLists?.records?.map((event: any) => (
                <MenuItem value={event?._id}>{event?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {id !== "" && (
            <div className="prograss-section">
              <div className="applicant">
                <div className="text">
                  <h2>Total Attendees</h2>
                  <h1>{Total_Attendees}</h1>
                </div>
              </div>
              <div className="interview">
                <div className="text">
                  <h2>Total # of Universities Represented</h2>
                  <h1>{Total_University}</h1>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr className="underline" />
      </div>

      {id !== "" ? (
        loadingSummary ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-55%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="dropdown-menu">
              <div className="grap-type">
                <p>Graph Type</p>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    defaultValue={2}
                    autoWidth
                    style={{
                      color: "#7F00BB",
                      background: "#F8F9FA",
                      height: "35px",
                      fontSize: "12px",
                      fontWeight: "600",
                      borderRadius: "10px",
                    }}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={1}>Bar Graph</MenuItem>
                    <MenuItem value={2}>Pie Chart</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="View-Data">
                <p>View Data</p>

                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    defaultValue={"university"}
                    // onChange={handlePaginationChange}
                    autoWidth
                    style={{
                      color: "#7F00BB",
                      background: "#F8F9FA",
                      height: "35px",
                      fontSize: "12px",
                      fontWeight: "600",
                      borderRadius: "10px",
                    }}
                    onChange={handleViewChange}
                  >
                    <MenuItem value={"university"}>University</MenuItem>
                    <MenuItem value={"gpa"}>GPA</MenuItem>
                    <MenuItem value={"graduation_year"}>
                      Graduation Year
                    </MenuItem>
                    <MenuItem value={"job_function"}>Job Function</MenuItem>
                    <MenuItem value={"diversity"}>Diversity</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {hideViewBy && (
                <div className="View-Data">
                  <p>View By</p>
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      defaultValue={selectNumber}
                      autoWidth
                      style={{
                        color: "#7F00BB",
                        background: "#F8F9FA",
                        height: "35px",
                        fontSize: "12px",
                        fontWeight: "600",
                        borderRadius: "10px",
                      }}
                      onChange={handleTopChoose}
                    >
                      <MenuItem value={10}> 10</MenuItem>
                      <MenuItem value={15}> 15</MenuItem>
                      <MenuItem value={20}> 20</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>

            <div className="graph">
              {chooseGraph === 1 && (
                <>
                  <div
                    className="bar-graph"
                    style={
                      size > 700 && size < 780
                        ? {
                            height: `${
                              selectNumber <= 10
                                ? selectNumber * 30
                                : selectNumber * 20
                            }px`,
                            width: `${
                              selectNumber <= 15
                                ? selectNumber * 60
                                : selectNumber * 40
                            }px`,
                          }
                        : {
                            height: `${
                              selectNumber <= 10
                                ? selectNumber * 50
                                : selectNumber * 30
                            }px`,
                            width: `${
                              selectNumber <= 10
                                ? selectNumber * 90
                                : selectNumber * 60
                            }px`,
                          }
                    }
                  >
                    {!loading && (
                      <ResponsiveBar
                        data={mapData}
                        keys={key1}
                        indexBy="id"
                        colors={[
                          "#FCA3CC",
                          "#BEAEE2",
                          "#FCD8D4",
                          "#FFAFAF",
                          "#D77FA1",
                          "#EDD2F3",
                          "#FFDEFA",
                          "#F5ABC9",
                          "#FDE0DD",
                          "#FFC288",
                          "#B8B5FF",
                          "#F190B7",
                          "#FCD1D1",
                          "#6FB2D2",
                          "#F473B9",
                          "#F7F7F7",
                          "#FCF8EC",
                          "#D2F5E3",
                          "#FFC1F3",
                          "#FCCBCB",
                        ]}
                        colorBy="id"
                        margin={{ top: 50, right: 300, bottom: 50, left: 60 }}
                        padding={0.3}
                        innerPadding={5}
                        layout="vertical"
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        borderRadius={12}
                        borderColor={{
                          from: "color",
                          modifiers: [["darker", 3]],
                        }}
                        animate={true}
                        axisTop={null}
                        axisRight={null}
                        // axisBottom={{
                        //   tickSize: 0,
                        //   tickPadding: 5,
                        //   tickRotation: 0,
                        //   legend: `${viewName}`,
                        //   legendPosition: "middle",
                        //   legendOffset: 32,
                        //   "format": () => null,
                        // }}
                        axisBottom={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,

                          legendPosition: "middle",
                          legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                          from: "color",
                          modifiers: [["darker", 2]],
                        }}
                        legends={[
                          {
                            dataFrom: "keys",
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: "left-to-right",
                            itemOpacity: 0.85,
                            symbolSize: 20,
                          },
                        ]}
                        theme={{
                          fontSize: 15,
                          grid: {
                            line: {
                              stroke: "#ffff",
                              strokeWidth: 0,
                              strokeDasharray: "6",
                            },
                          },
                          axis: {
                            legend: {
                              text: {
                                fontSize: 20,
                                fontWeight: 600,
                                fontFamily: "Poppins, sans-serif",
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                  {/* <h1 style={{marginTop:"-30px",fontSize:"16px",marginBottom:"20px"}}>{data.length !== 0 && `${viewName.charAt(0).toUpperCase() + viewName.slice(1)}`}</h1> */}
                  {data.length !== 0 && (
                    <div style={{ float: "right", marginTop: "-30px" }}>
                      <h1
                        className="h1ie"
                        style={{
                          marginBottom: "20px",
                          fontSize: "18px",
                          color: "#7F00BB",
                        }}
                      >
                        {viewName === "university" && "University "}
                      </h1>
                      <h1
                        className="pie"
                        style={{
                          marginBottom: "20px",
                          fontSize: "18px",
                          color: "#7F00BB",
                        }}
                      >
                        {viewName === "diversity" && "Diversity "}
                      </h1>
                      <h1
                        className="pie"
                        style={{
                          marginBottom: "20px",
                          fontSize: "18px",
                          color: "#7F00BB",
                        }}
                      >
                        {viewName === "graduation_year" && "Graduation Year"}
                      </h1>
                      <h1
                        className="pie"
                        style={{
                          marginBottom: "20px",
                          fontSize: "18px",
                          color: "#7F00BB",
                        }}
                      >
                        {viewName === "job_function" && "Job Function "}
                      </h1>
                      <h1
                        className="pie"
                        style={{
                          marginBottom: "20px",
                          fontSize: "18px",
                          color: "#7F00BB",
                        }}
                      >
                        {viewName === "gpa" && "GPA"}
                      </h1>
                    </div>
                  )}
                  <div className="pagination">
                    <Pagination
                      onChange={pageHandler}
                      count={Math.ceil(total_University / selectNumber)}
                      color="secondary"
                      shape="circular"
                    />
                  </div>
                </>
              )}
              {chooseGraph === 2 && (
                <div className="graph-pie-chart">
                  <PieChart data={eventSummary} view={viewName} />
                </div>
              )}
            </div>
          </>
        )
      ) : (
        <div className="default-text">
          <div>
            <img
              src="https://s1.ecnstatic.com/ecn/images/common/laptop.png"
              alt=""
            />
            <p>Please Select an Event</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LayoutsSide(EventSummary);
