import axios from "../AxiosInterceptor";
import {
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_ATTENDEE_DETAIL_REQUEST,
  EVENT_ATTENDEE_DETAIL_SUCCESS,
  EVENT_ATTENDEE_DETAIL_FAIL,
  EVENT_DATA_REQUEST,
  EVENT_DATA_SUCCESS,
  EVENT_DATA_FAIL,
  EVENT_SUMMARY_REQUEST,
  EVENT_SUMMARY_SUCCESS,
  EVENT_SUMMARY_FAIL,
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_SUCCESS,
  EVENT_CREATE_FAIL,
  EVENT_DETAILS_REQUEST,
  EVENT_DETAILS_SUCCESS,
  EVENT_DETAILS_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  BULK_CERTIFICATE_ISSUE_REQUEST,
  BULK_CERTIFICATE_ISSUE_SUCCESS,
  BULK_CERTIFICATE_ISSUE_FAIL,
} from "../Constants/eventConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const getEventAttendeeDetail = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_FAIL,
      payload: error.message,
    });
  }
};

export const getEventList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/event/user`, config);

    // Dispatch the success action
    dispatch({
      type: EVENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const getEventDetails = (eventId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${BASE}/api/v1/event/${eventId}`);

    // Dispatch the success action
    dispatch({
      type: EVENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const getEventData = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_DATA_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/event/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_DATA_FAIL,
      payload: error.message,
    });
  }
};

export const getEventSummary = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_SUMMARY_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/summary/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_SUMMARY_FAIL,
      payload: error.message,
    });
  }
};

export const postEventCreate = (eventData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_CREATE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/event`,
      eventData,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_CREATE_FAIL,
      payload: error.message,
    });
  }
};

export const putEventUpdate = (eventData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_UPDATE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(`${BASE}/api/v1/event`, eventData, config);

    // Dispatch the success action
    dispatch({
      type: EVENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_UPDATE_FAIL,
      payload: error.message,
    });
  }
};

export const certificateIssue =
  (certificates: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: BULK_CERTIFICATE_ISSUE_REQUEST,
      });

      const token = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      };

      const { data } = await axios.post(
        `${BASE}/api/v1/certificate/bulk_issue`,
        certificates,
        config
      );

      // Dispatch the success action
      dispatch({
        type: BULK_CERTIFICATE_ISSUE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BULK_CERTIFICATE_ISSUE_FAIL,
        payload: error.message,
      });
    }
  };
