import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Autocomplete } from "@mui/material";

const EducationFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const dispatch = useDispatch();

  const educationHandler = (newVal: any, input: string) => {
    if (input === "education_type") {
      setUserData({
        ...userData,
        education: {
          ...userData.education,
          [input]: newVal === "Undergrad" ? 0 : 1,
        },
      });
    } else if (input === "graduation_year") {
      setUserData({
        ...userData,
        education: {
          ...userData.education,
          [input]: Number(newVal),
        },
      });
    } else {
      setUserData({
        ...userData,
        education: {
          ...userData.education,
          [input]: newVal,
        },
      });
    }
  };
  return (
    <div className="education_fields">
      {userData.education.map((edu: any, index: number) => (
        <Fragment key={index}>
          <div className="row">
            <Autocomplete
              sx={{ width: "100%" }}
              disableClearable
              options={eduLevel}
              defaultValue={edu?.education_type === 1 ? "MBA" : "Undergrad"}
              onChange={(e, value) => educationHandler(value, "education_type")}
              renderInput={(params) => (
                <TextField {...params} label="Education Level" required />
              )}
            />

            <Autocomplete
              freeSolo
              disableClearable
              sx={{ width: "100%" }}
              value={edu.name}
              onChange={(e, value) => educationHandler(value, "name")}
              options={edu?.education_type === 1 ? [""] : [""]}
              renderInput={(params) => (
                <TextField {...params} label="University" required />
              )}
            />
          </div>

          <div className="row">
            <Autocomplete
              disableClearable
              value={edu.partner_club}
              options={edu.education_type === 1 ? [""] : [""]}
              getOptionLabel={(organization) => organization}
              onChange={(e, value) => educationHandler(value, "partner_club")}
              renderInput={(params: any) => (
                <TextField {...params} label="Elevate Partner Club" required />
              )}
            />
            <Autocomplete
              sx={{ width: "100%" }}
              options={gradYear}
              disableClearable
              value={edu?.graduation_year !== 0 ? edu?.graduation_year : ""}
              onChange={(e, value) =>
                educationHandler(value, "graduation_year")
              }
              renderInput={(params) => (
                <TextField {...params} label="Graduation Year" required />
              )}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default EducationFields;

const gradYear = [
  "2027",
  "2026",
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
];

const eduLevel = ["Undergrad", "MBA"];
