import {
  JOB_LIST_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAIL,
  JOB_CANDIDATE_LIST_SUCCESS,
  JOB_CANDIDATE_LIST_REQUEST,
  JOB_CANDIDATE_LIST_FAIL,
  CANDIDATE_DETAILS_FAIL,
  CANDIDATE_DETAILS_REQUEST,
  CANDIDATE_DETAILS_SUCCESS,
  BULK_RESUME_REQUEST,
  BULK_RESUME_SUCCESS,
  BULK_RESUME_FAIL,
  APPLICANT_STATUS_REQUEST,
  APPLICANT_STATUS_SUCCESS,
  APPLICANT_STATUS_FAIL,
  APPLICANT_SCORE_REQUEST,
  APPLICANT_SCORE_SUCCESS,
  APPLICANT_SCORE_FAIL,
  SCORE_LIST_REQUEST,
  SCORE_LIST_SUCCESS,
  SCORE_LIST_FAIL,
  SCORE_PARAM_UPDATE_REQUEST,
  SCORE_PARAM_UPDATE_SUCCESS,
  SCORE_PARAM_UPDATE_FAIL,
  SCORE_ENABLE_REQUEST,
  SCORE_ENABLE_SUCCESS,
  SCORE_ENABLE_FAIL,
  JOB_SUMMARY_REQUEST,
  JOB_SUMMARY_SUCCESS,
  JOB_SUMMARY_FAIL,
  JOB_CREATE_REQUEST,
  JOB_CREATE_SUCCESS,
  JOB_CREATE_FAIL,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_SUCCESS,
  JOB_UPDATE_FAIL,
  RESUME_TOKEN_REQUEST,
  RESUME_TOKEN_SUCCESS,
  RESUME_TOKEN_FAIL,
} from "../Constants/jobConstants";

export const jobListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_LIST_REQUEST:
      return { loading: true };

    case JOB_LIST_SUCCESS:
      return {
        jobList: action.payload,
        loading: false,
        error: null,
      };

    case JOB_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobDetailReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_DETAILS_REQUEST:
      return { loading: true };

    case JOB_DETAILS_SUCCESS:
      return {
        job: action.payload,
        loading: false,
        error: false,
      };

    case JOB_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobCandidateListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_CANDIDATE_LIST_REQUEST:
      return { loading: true };

    case JOB_CANDIDATE_LIST_SUCCESS:
      return {
        candidateList: action.payload,
        loading: false,
        error: false,
      };

    case JOB_CANDIDATE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const candidateDetailsReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CANDIDATE_DETAILS_REQUEST:
      return { loading: true };

    case CANDIDATE_DETAILS_SUCCESS:
      return {
        candidateDetail: action.payload,
        loading: false,
        error: false,
      };

    case CANDIDATE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bulkResumeReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BULK_RESUME_REQUEST:
      return { loading: true };

    case BULK_RESUME_SUCCESS:
      return {
        zipFile: action.payload,
        loading: false,
        error: false,
      };

    case BULK_RESUME_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const statusJobReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case APPLICANT_STATUS_REQUEST:
      return { loading: true };

    case APPLICANT_STATUS_SUCCESS:
      return {
        loading: false,
        status: action.payload,
        error: false,
      };

    case APPLICANT_STATUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const scoreJobReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case APPLICANT_SCORE_REQUEST:
      return { loading: true };

    case APPLICANT_SCORE_SUCCESS:
      return {
        loading: false,
        score: action.payload,
        success: true,
        error: false,
      };

    case APPLICANT_SCORE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const scoreJobParamReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SCORE_LIST_REQUEST:
      return { loading: true };

    case SCORE_LIST_SUCCESS:
      return {
        loading: false,
        scoreParams: action.payload,
        error: false,
      };

    case SCORE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const scoreParamUpdateReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SCORE_PARAM_UPDATE_REQUEST:
      return { loading: true };

    case SCORE_PARAM_UPDATE_SUCCESS:
      return {
        loading: false,
        scoreParams: action.payload,
        error: false,
      };

    case SCORE_PARAM_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const enableScoreReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SCORE_ENABLE_REQUEST:
      return { loading: true };

    case SCORE_ENABLE_SUCCESS:
      return {
        loading: false,
        status: action.payload,
        success: true,
        error: false,
      };

    case SCORE_ENABLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobSummaryReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_SUMMARY_REQUEST:
      return { loading: true };

    case JOB_SUMMARY_SUCCESS:
      return {
        jobSummary: action.payload,
        loading: false,
        error: null,
      };

    case JOB_SUMMARY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobCreateReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_CREATE_REQUEST:
      return { loading: true };

    case JOB_CREATE_SUCCESS:
      return {
        jobCreate: action.payload,
        success: true,
        loading: false,
        error: null,
      };

    case JOB_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobUpdateReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_UPDATE_REQUEST:
      return { loading: true };

    case JOB_UPDATE_SUCCESS:
      return {
        updatedJob: action.payload,
        success: true,
        loading: false,
        error: null,
      };

    case JOB_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const resumeTokenReducer = (
  state = [],
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case RESUME_TOKEN_REQUEST:
      return { loading: true };

    case RESUME_TOKEN_SUCCESS:
      return {
        resumeAccess: action.payload,
        loading: false,
        error: false,
        success: true,
      };

    case RESUME_TOKEN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
