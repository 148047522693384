import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateUserById, userById } from "../../redux/Actions/userActions";

import { Alert, AlertTitle, CircularProgress, Button } from "@mui/material";

import LayoutsSide from "../../components/Layout/LayoutsSide";
import BasicFields from "../../components/EditUser/BasicFields";
import EducationFields from "../../components/EditUser/EducationFields";
import WorkFields from "../../components/EditUser/WorkFields";
import PreferenceFields from "../../components/EditUser/PreferenceFields";
import SettingFields from "../../components/EditUser/SettingFields";
import MetricsFields from "../../components/EditUser/MetricsFields";
import SocialFields from "../../components/EditUser/SocialFields";
import OverviewFields from "../../components/EditUser/OverviewFields";

const UserDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { user } = useSelector((state: any) => state.userDetailsById);
  const { success, loading } = useSelector(
    (state: any) => state.userDetailsById
  );

  const [err, setErr] = useState({ status: false, message: "" });
  const [userData, setUserData] = useState<any>({
    _id: "",
    account_type: 0,
    act: 0,
    affinity_groups: [],
    banner: null,
    bio: "",
    city: "",
    connection_count: 0,
    display_pictures: null,
    education: [
      {
        education_type: 0,
        entry_year: 0,
        graduation_year: 2020,
        logo: "",
        major: "",
        name: "",
        partner_club: "",
      },
    ],
    education_level: 0,
    email: "",
    email_verified: true,
    featured: null,
    finnect_dates: [],
    finnect_signup: false,
    first_name: "",
    gmat: 0,
    gpa: 0,
    hash: 871739916,
    headline: "",
    last_name: "",
    locked: false,
    mobile: "",
    mobile_verified: false,
    preferences: {
      objectives: [],
      preferred_careers: [],
      preferred_industries: [],
      interests: [],
    },
    redeem_points: 0,
    referral_code: "",
    resume: "",
    sat: 0,
    setup_stage: 0,
    socials: [
      {
        name: "",
        social_type: 0,
        url: "",
      },
    ],
    timestamp: 0,
    user_name: "",
    work_experiences: [
      {
        city: "",
        company: "",
        end_date: "",
        experience_status: 0,
        experience_type: 0,
        industry: "",
        position: "",
        start_date: "",
        title: "",
        zip_code: "",
      },
    ],
    zoom_integration: false,
  });

  useEffect(() => {
    if (user) setUserData(user);
  }, [user]);

  useEffect(() => {
    if (params.userId) {
      dispatch(userById(params.userId));
    }
  }, [params.userId]);

  // if (loading) {
  //   return <Loader />;
  // }

  const submitHandler = () => {
    dispatch(updateUserById(userData));
  };

  return (
    <div className="user-details">
      <div className="head-text">
        <h1>Welcome!</h1>
        <h2>Please update user data CAREFULLY..</h2>
      </div>

      <div className="all-details">
        <div className="overview">
          <h2>
            Overview <span>(images will be updated immediately)</span>
          </h2>

          <OverviewFields
            userData={userData}
            setUserData={setUserData}
            userId={params.userId ? params.userId : ""}
          />
        </div>

        <div className="basic">
          <h2>Basic Details</h2>

          <BasicFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="metric">
          <h2>Metrics</h2>

          <MetricsFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="education">
          <h2>Education</h2>

          <EducationFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="work">
          <h2>Work Experience</h2>

          <WorkFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="preference">
          <h2>Preferences</h2>

          <PreferenceFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="social">
          <h2>Socials</h2>

          <SocialFields userData={userData} setUserData={setUserData} />
        </div>

        <div className="settings">
          <h2>Settings</h2>

          <SettingFields userData={userData} setUserData={setUserData} />
        </div>
      </div>

      <div className="submit">
        {err.status && (
          <Alert severity="error">
            <AlertTitle style={{ textAlign: "left" }}>
              Incomplete Data
            </AlertTitle>
            {err.message}
          </Alert>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default LayoutsSide(UserDetails);
