import { useState } from "react";
import { Switch, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "500ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#7F00BB" : "#7F00BB",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#7F00BB",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SettingFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {
  const [outside, setOutside] = useState(false);

  const changeHandler = (input: any) => {
    const tempData = { ...data };
    tempData[input] = !tempData[input];
    update(tempData);
  };

  const redirectHandler = (e: any) => {
    const tempData = { ...data };
    tempData.redirect = e.target.value;
    update(tempData);
  };
  return (
    <div className="settings_fields">
      <div className="row">
        <Typography variant="h6" component="h6">
          Hidden
        </Typography>
        <IOSSwitch
          checked={data.hidden}
          onChange={() => changeHandler("hidden")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Closed
        </Typography>
        <IOSSwitch
          checked={data.closed}
          onChange={() => changeHandler("closed")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Apply Outside
        </Typography>
        <IOSSwitch checked={outside} onChange={() => setOutside(!outside)} />
      </div>
      {outside && (
        <TextField
          label="Link to Job Board"
          value={data.redirect}
          onChange={redirectHandler}
        />
      )}
    </div>
  );
};

export default SettingFields;
