import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getJobList } from "../../redux/Actions/jobActions";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const JobList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobList, loading, error } = useSelector(
    (state: any) => state.jobList
  );

  useEffect(() => {
    dispatch(getJobList());
  }, []);

  const clickHandler = (e: any) => {
    navigate(`/job/edit?type=existing&jobId=${e.target.value}`);
  };

  return (
    <div style={{margin: "100px"}}>
      <h1>Job List</h1>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 400 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Job</InputLabel>
          <Select
            defaultValue="0"
            onChange={clickHandler}
            autoWidth
            label="Job"
            style={{
              width: "400px",
              overflow: "hidden",
              height: "45px",
              color: "#7f00bb",
              background: "rgba(127, 0, 187, 0.08)",
              fontWeight: "500",
            }}
          >
            <MenuItem value="0" disabled>
              Select Job
            </MenuItem>
            {jobList?.records?.map((application: any) => (
              <MenuItem value={application._id}>
                {application.job_title + " - " + application._id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={() => navigate("/job/edit?type=new")}
        >
          Create New Job
        </Button>
      </div>
    </div>
  );
};

export default LayoutsSide(JobList);
