import React from "react";

import { TextField, Autocomplete } from "@mui/material";

import { affinityGroups } from "../../helpers/content/preferences";

const BasicFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const basicDetailHandler = (e: any, input: any) => {
    setUserData({ ...userData, [input]: e.target.value });
  };

  const affinityHandler = (value: any) => {
    if (userData.affinity_groups === null) {
      userData.affinity_groups = [];
    }
    setUserData({ ...userData, affinity_groups: value });
  };
  return (
    <div className="basic_fields">
      <div className="row">
        <TextField
          required
          label="First Name"
          value={userData.first_name}
          onChange={(e) => basicDetailHandler(e, "first_name")}
        />
        <TextField
          required
          label="Last Name"
          value={userData.last_name}
          onChange={(e) => basicDetailHandler(e, "last_name")}
        />
      </div>

      <div className="row">
        <TextField
          required
          label="Email"
          value={userData.email}
          onChange={(e) => basicDetailHandler(e, "email")}
        />

        <TextField
          required
          label="Mobile"
          value={userData.mobile}
          onChange={(e) => basicDetailHandler(e, "mobile")}
        />
      </div>

      <Autocomplete
        multiple
        disableClearable
        id="affinity-outlined"
        value={userData.affinity_groups ? userData.affinity_groups : []}
        onChange={(e, newVal) => affinityHandler(newVal)}
        options={affinityGroups}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add affinity groups here (Type and press Enter)"
          />
        )}
      />
    </div>
  );
};

export default BasicFields;
