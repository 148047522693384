import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";

const QuestionFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {
  const [open, setOpen] = useState(false);
  const [que, setQue] = useState(questionTemplate);

  const questionAddHandler = (e: any, input: string) => {
    if (input === "options") {
      setQue({
        ...que,
        options: e,
      });
    } else {
      setQue({
        ...que,
        [input]: e.target.value,
      });
    }
  };
  const selectHandler = (e: any) => {
    if (e.target.value === -4) {
      setQue({
        ...que,
        key: "affinity_groups",
        type: 5,
        name: "Which affinity group do you associate with, if any?",
        options: [
          "Women",
          "Black",
          "Latinx",
          "Asian",
          "Multi-Racial",
          "LGBTQ+",
          "Disability",
          "Other Diversity",
          "None of the Above",
          "Prefer not to say",
        ],
      });
    } else if (e.target.value === -3) {
      setQue({
        ...que,
        key: "resume",
        name: "Upload your resume (Pdf preferred)",
        type: 6,
      });
    } else {
      setQue({
        ...que,
        type: e.target.value,
      });
    }
  };

  const createQuestionHandler = () => {
    const tempQues = data.job_questions;
    update({
      ...data,
      job_questions: [...tempQues, que],
    });
    setQue(questionTemplate);
    setOpen(false);
  };

  const closeHandler = () => {
    setQue(questionTemplate);
    setOpen(false);
  };
  return (
    <div className="questions_fields">
      <div className="ques">
        {data.job_questions.length > 0 &&
          data.job_questions.map((que: any, index: number) => (
            <div key={index} className="que">
              <h3>{index + 1 + ". " + que.name}</h3>
              {que.type === 5 && <h4>{que.options.toString()}</h4>}
            </div>
          ))}
      </div>

      <Button
        onClick={() => setOpen(true)}
        className="add-btn"
        variant="contained"
      >
        Add Question
      </Button>

      <Dialog maxWidth="md" open={open} onClose={closeHandler}>
        <DialogContent>
          <Box sx={{ width: 800 }}>
            <div style={{ display: "flex", columnGap: 30, margin: "20px 0" }}>
              <Select
                sx={{ width: 180 }}
                value={que.type}
                onChange={selectHandler}
              >
                <MenuItem value={-1} disabled>
                  Question Type
                </MenuItem>
                <MenuItem value={0}>Sentence</MenuItem>
                <MenuItem value={-2}>Select</MenuItem>
                <MenuItem value={5}>Multi Select</MenuItem>
                <MenuItem value={-4}>Affinity</MenuItem>
                <MenuItem value={-3}>Resume</MenuItem>
                <MenuItem value={6}>Files</MenuItem>
                <MenuItem value={7}>Academic</MenuItem>
                <MenuItem value={8}>Yes/No</MenuItem>
              </Select>

              <TextField
                sx={{ width: "100%" }}
                label="Question"
                value={que.name}
                onChange={(e) => questionAddHandler(e, "name")}
              />
            </div>

            {(que.type === 5 || que.type === -2 || que.type === -4) && (
              <Autocomplete
                multiple
                freeSolo
                disableClearable
                disabled={que.key === "affinity_groups"}
                value={que.options}
                onChange={(e, newVal) => questionAddHandler(newVal, "options")}
                options={[
                  "Private Equity",
                  "Dummy",
                  "Venture Capital",
                  "Hedge Fund",
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add options here (Type and press Enter)"
                    placeholder="Options"
                  />
                )}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeHandler} variant="outlined">
            Cancel
          </Button>
          <Button onClick={createQuestionHandler} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuestionFields;

const questionTemplate = {
  key: "",
  name: "",
  options: [] as string[],
  required: true,
  required_if_empty: [] as string[],
  save: true,
  type: -1,
  values: [] as string[],
};
