import axios from "../AxiosInterceptor";
import {
  EMAIL_TEMPLATE_LIST_REQUEST,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_LIST_FAIL,
  EMAIL_FILTER_LIST_REQUEST,
  EMAIL_FILTER_LIST_SUCCESS,
  EMAIL_FILTER_LIST_FAIL,
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
} from "../Constants/emailConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const getTemplateList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/block/62975725bdea9a5540d4c99a`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EMAIL_TEMPLATE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EMAIL_TEMPLATE_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const getFilterList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: EMAIL_FILTER_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/campaign/elevate_users/filters`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EMAIL_FILTER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EMAIL_FILTER_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const postEmails = (mail: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EMAIL_SEND_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/campaign/elevate_users/demo`,
      mail,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EMAIL_SEND_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EMAIL_SEND_FAIL,
      payload: error.message,
    });
  }
};
