import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  candidateDetailsReducer,
  bulkResumeReducer,
  statusJobReducer,
  scoreJobReducer,
  scoreJobParamReducer,
  scoreParamUpdateReducer,
  enableScoreReducer,
  jobListReducer,
  jobCandidateListReducer,
  jobSummaryReducer,
  jobCreateReducer,
  jobDetailReducer,
  jobUpdateReducer,
  resumeTokenReducer,
} from "./Reducers/jobReducers";

import {
  userSignInReducer,
  userDetailsReducer,
  userPasswordChangeReducer,
  userForgetMailReducer,
  userListReducer,
  userFinnectListReducer,
  filteredUserListReducer,
  searchedUserListReducer,
  searchedFinnectUserListReducer,
  adminImageUploadReducer,
  userDetailsByIdReducer,
  userUpdateByIdReducer,
  userChangeProfileImageReducer,
  userBannerUpdateReducer,
  bulkDownloadAllUsersReducer,
  bulkDownloadAllFinnectUsersReducer
} from "./Reducers/userReducers";
import {
  eventAttendeeDetailReducer,
  eventCreateReducer,
  eventDataReducer,
  eventDetailReducer,
  eventListReducer,
  eventSummaryReducer,
  eventUpdateReducer,
  certificateIssueReducer
} from "./Reducers/eventReducers";
import {
  emailFilterListReducer,
  emailSendReducer,
  emailTemplateListReducer,
} from "./Reducers/emailReducers";

// REDUCERS GO HERE
const reducers = combineReducers({
  //USER
  signIn: userSignInReducer,
  userDetails: userDetailsReducer,
  userPasswordChange: userPasswordChangeReducer,
  userForgetMail: userForgetMailReducer,

  userList: userListReducer,
  userFinnectList: userFinnectListReducer,
  filteredUserList: filteredUserListReducer,
  searchedUserList: searchedUserListReducer,
  searchedFinnectUserList: searchedFinnectUserListReducer,

  adminImageUpload: adminImageUploadReducer,

  userChangeProfileImage: userChangeProfileImageReducer,
  userBannerUpdate: userBannerUpdateReducer,

  userDetailsById: userDetailsByIdReducer,
  userUpdateById: userUpdateByIdReducer,
  bulkUsersReducer: bulkDownloadAllUsersReducer,
  bulkFinnectUsersReducer: bulkDownloadAllFinnectUsersReducer,


  //EVENTS
  eventList: eventListReducer,
  eventDetail: eventDetailReducer,
  eventCreate: eventCreateReducer,
  eventUpdate: eventUpdateReducer,
  eventAttendeeDetail: eventAttendeeDetailReducer,
  eventData: eventDataReducer,
  eventSummary: eventSummaryReducer,
  bulksCertificates: certificateIssueReducer,

  //JOBS
  jobList: jobListReducer,
  jobDetail: jobDetailReducer,
  jobCreate: jobCreateReducer,
  jobUpdate: jobUpdateReducer,
  jobSummary: jobSummaryReducer,
  jonCandidateList: jobCandidateListReducer,
  candidateDetails: candidateDetailsReducer,

  bulkResume: bulkResumeReducer,
  resumeToken: resumeTokenReducer,

  statusJob: statusJobReducer,
  scoreJob: scoreJobReducer,
  scoreJobParam: scoreJobParamReducer,
  scoreParamUpdate: scoreParamUpdateReducer,
  scoreEnable: enableScoreReducer,

  //EMAILS
  emailTemplateList: emailTemplateListReducer,
  emailFilterList: emailFilterListReducer,
  emailSend: emailSendReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")!)
  : null;

const initialState = {
  // USE THIS TO INITIALIZE YOUR STATE
  userDetails: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
