import React from "react";

import { MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "500ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#7F00BB" : "#7F00BB",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#7F00BB",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SettingFields: React.FC<{ userData: any; setUserData: any }> = ({
  userData,
  setUserData,
}) => {
  const changeHandler = (input: any) => {
    const tempData = { ...userData };
    tempData[input] = !tempData[input];
    setUserData(tempData);
  };

  const accountHandler = (e: any) => {
    setUserData({
      ...userData,
      account_type: e.target.value,
    });
  };

  return (
    <div className="settings_fields">
      <div style={{marginBottom: 40}} className="row">
        <Typography variant="h6" component="h6">
          Account Type
        </Typography>
        <Select value={userData.account_type} onChange={accountHandler}>
          <MenuItem value={0}>Base User</MenuItem>
          <MenuItem value={1}>Recruiter</MenuItem>
        </Select>
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Locked
        </Typography>
        <IOSSwitch
          checked={userData.locked}
          onChange={() => changeHandler("locked")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Email Verified
        </Typography>
        <IOSSwitch
          checked={userData.email_verified}
          onChange={() => changeHandler("email_verified")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Finnect Sign Up
        </Typography>
        <IOSSwitch
          checked={userData.finnect_signup}
          onChange={() => changeHandler("finnect_signup")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Mobile Verified
        </Typography>
        <IOSSwitch
          checked={userData.mobile_verified}
          onChange={() => changeHandler("mobile_verified")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Zoom Integration
        </Typography>
        <IOSSwitch
          checked={userData.zoom_integration}
          onChange={() => changeHandler("zoom_integration")}
        />
      </div>
    </div>
  );
};

export default SettingFields;
