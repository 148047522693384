export const EVENT_ATTENDEE_DETAIL_SUCCESS = "EVENT_ATTENDEE_DETAIL_SUCCESS";
export const EVENT_ATTENDEE_DETAIL_FAIL = "EVENT_ATTENDEE_DETAIL_FAIL";
export const EVENT_ATTENDEE_DETAIL_REQUEST = "EVENT_ATTENDEE_DETAIL_REQUEST";

export const EVENT_LIST_REQUEST = "EVENT_LIST_REQUEST";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_FAIL = "EVENT_LIST_FAIL";

export const EVENT_DETAILS_REQUEST = "EVENT_DETAILS_REQUEST";
export const EVENT_DETAILS_SUCCESS = "EVENT_DETAILS_SUCCESS";
export const EVENT_DETAILS_FAIL = "EVENT_DETAILS_FAIL";

export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_FAIL = "EVENT_CREATE_FAIL";

export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAIL = "EVENT_UPDATE_FAIL";

export const EVENT_DATA_REQUEST = "EVENT_DATA_REQUEST";
export const EVENT_DATA_SUCCESS = "EVENT_DATA_SUCCESS";
export const EVENT_DATA_FAIL = "EVENT_DATA_FAIL";

export const EVENT_SUMMARY_REQUEST = "EVENT_SUMMARY_REQUEST";
export const EVENT_SUMMARY_SUCCESS = "EVENT_SUMMARY_SUCCESS";
export const EVENT_SUMMARY_FAIL = "EVENT_SUMMARY_FAIL";

export const BULK_CERTIFICATE_ISSUE_REQUEST = "BULK_CERTIFICATE_ISSUE_REQUEST";
export const BULK_CERTIFICATE_ISSUE_SUCCESS = "BULK_CERTIFICATE_ISSUE_SUCCESS";
export const BULK_CERTIFICATE_ISSUE_FAIL = "BULK_CERTIFICATE_ISSUE_FAIL";
