import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getEventList } from "../../redux/Actions/eventActions";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const EventList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { eventLists, loading, error } = useSelector(
    (state: any) => state.eventList
  );

  useEffect(() => {
    dispatch(getEventList());
  }, []);

  const clickHandler = (e: any) => {
    navigate(`/event/edit?type=existing&eventId=${e.target.value}`);
  };

  return (
    <div style={{ margin: 100 }}>
      <h1>Event List</h1>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 400 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Job</InputLabel>
          <Select
            defaultValue="0"
            onChange={clickHandler}
            label="Job"
            style={{
              width: "400px",
              overflow: "hidden",
              height: "45px",
              color: "#7f00bb",
              background: "rgba(127, 0, 187, 0.08)",
              fontWeight: "500",
            }}
          >
            <MenuItem value="0" disabled>
              Select Event
            </MenuItem>
            {eventLists?.records?.map((application: any) => (
              <MenuItem value={application._id}>
                {application.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={() => navigate("/event/edit?type=new")}
        >
          Create New Job
        </Button>
      </div>
    </div>
  );
};

export default LayoutsSide(EventList);
