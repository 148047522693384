import { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getEventData, getEventList } from "../../redux/Actions/eventActions";

import EventCandidateDetails from "../../components/Events/EventCandidateDetails";
import EventsTable from "../../components/Events/EventsTable";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const Events = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [org, setOrg] = useState<any>();
  const [active, setActive] = useState(0);
  const [index, setIndex] = useState(-1);
  const [searchParams] = useSearchParams();
  const [checkEvent, setCheckEvent] = useState<any>();
  const [eventDate, setEventDate] = useState<any>();
  const [eventName, setEventName] = useState<any>();
  let view = searchParams.get("view");
  let eventId = searchParams.get("eventId");

  useEffect(() => {
    dispatch(getEventList());
  }, []);
  const { eventLists, loading, error } = useSelector(
    (state: any) => state.eventList
  );

  const [eventType, setEventType] = useState("");

  useEffect(() => {
    dispatch(getEventData(eventType));
  }, [eventType]);
  const { events } = useSelector((state: any) => state.eventData);

  useEffect(() => {
    if (index >= 0 && index < events?.records.length) {
      navigate(
        `?view=details&eventId=${
          eventType === undefined ? eventId : eventType
        }&registrationId=${events?.records[index]._id}&index=${index}`
      );
    }
  }, [index]);

  useEffect(() => {
    if (!location.search) {
      navigate("?view=list&display=all");
    } else if (view === "details") {
      setActive(1);
    } else if (view === "list") {
      setActive(0);
    }
  }, [location.search]);

  useEffect(() => {
    eventLists?.records?.map((item: any) => {
      if (item?._id === eventType) {
        setCheckEvent(item?.paid_event);
        setEventDate(item?.start_date);
        setEventName(item?.name);
        setOrg(item?.organisations?.length === 0 ? "" : item?.organisations[0]);
      }
    });
  }, [events]);

  return (
    <div className="events">
      {active === 0 ? (
        <EventsTable
          eventType={eventType}
          setEventType={setEventType}
          eventLists={eventLists}
          events={events}
          loading={loading}
          setIndex={setIndex}
          checkEvent={checkEvent}
          eventName={eventName}
          eventDate={eventDate}
        />
      ) : (
        <EventCandidateDetails
          index={index}
          setIndex={setIndex}
          maxLength={events?.records?.length}
        />
      )}
    </div>
  );
};

export default LayoutsSide(Events);
