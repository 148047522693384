import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  postEventCreate,
  getEventDetails,
  putEventUpdate,
} from "../../redux/Actions/eventActions";

import {
  AlertTitle,
  Alert,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import BasicFields from "../../components/AddEvent/BasicFields";
import SettingFields from "../../components/AddEvent/SettingFields";
import OrgSpeakerFields from "../../components/AddEvent/OrgSpeakerFields";
import MediaFields from "../../components/AddEvent/MediaFields";
import QuestionFields from "../../components/AddEvent/QuestionFields";

import LayoutsSide from "../../components/Layout/LayoutsSide";

const EditEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let type = searchParams.get("type");
  let eventId = searchParams.get("eventId");

  const { event } = useSelector((state: any) => state.eventDetail);
  const { userInfo } = useSelector((state: any) => state.userDetails);
  const { loading, success } = useSelector((state: any) => state.eventCreate);
  const { loading: loadingUpdate, success: successUpdate } = useSelector(
    (state: any) => state.eventUpdate
  );

  const [err, setErr] = useState({ status: false, message: "" });
  const [eventData, setEventData] = useState({
    _id: "",
    access: [],
    address: { city: "", country: "" },
    capacity: 0,
    created_by: {
      _id: "",
      banner: null,
      bio: "",
      display_pictures: [],
      education: null,
      education_level: 0,
      first_name: "",
      headline: "",
      last_name: "",
      preferences: {
        objectives: [],
        preferred_careers: [],
        preferred_industries: [],
        interests: [],
      },
      socials: [{ name: "", social_type: 0, url: "" }],
      user_name: "",
      work_experiences: [],
    },
    description: "",
    display_picture: "",
    end_date: "",
    event_questions: [],
    event_settings: {
      hidden: false,
      show_registration: true,
      show_registration_count: false,
    },
    event_type: 0,
    event_video_link: "",
    images: [],
    industry: "",
    name: "",
    organisations: [],
    registration: null,
    registrations_count: 0,
    response: null,
    responses: [],
    schedule_date: "",
    speakers: [],
    start_date: "",
    tags: [],
  });

  useEffect(() => {
    if (userInfo) {
      setEventData({
        ...eventData,
        created_by: {
          ...eventData.created_by,
          _id: userInfo?._id,
          banner: userInfo?.banner,
          bio: userInfo?.bio,
          display_pictures: userInfo?.display_pictures,
          education: userInfo?.education,
          education_level: userInfo?.education_level,
          first_name: userInfo?.first_name,
          headline: userInfo?.headline,
          last_name: userInfo?.last_name,
          preferences: userInfo?.preferences,
          socials: userInfo?.socials,
          user_name: userInfo?.user_name,
          work_experiences: userInfo?.work_experiences,
        },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (type === null) {
      navigate("?type=new");
    } else if (type === "existing" && eventId) {
      dispatch(getEventDetails(eventId));
    } else if (type === "existing" && !eventId) {
      navigate("?type=new");
    }
  }, [type, eventId]);

  useEffect(() => {
    if (type === "existing" && event) {
      setEventData(event);
    }
  }, [event]);

  const submitHandler = () => {
    if (eventData.name === "") {
      setErr({ status: true, message: "Name can not be empty" });
    } else if (eventData.description === "") {
      setErr({ status: true, message: "Description can not be empty" });
    } else if (eventData.start_date === "") {
      setErr({ status: true, message: "Start Date can not be empty" });
    } else if (eventData.end_date === "") {
      setErr({ status: true, message: "End Date can not be empty" });
    } else if (eventData.tags.length === 0) {
      setErr({ status: true, message: "Tags can not be empty" });
    } else if (eventData.description === "") {
      setErr({ status: true, message: "Description can not be empty" });
    } else if (eventData.display_picture === "") {
      setErr({ status: true, message: "Display Picture can not be empty" });
    } else if (eventData.images.length === 0) {
      setErr({ status: true, message: "Banner Image can not be empty" });
    } else {
      if (eventData.schedule_date === "") {
        eventData.schedule_date = new Date().toISOString();
      }
      if (type === "new") {
        dispatch(postEventCreate(eventData));
      } else {
        console.log("True");
        dispatch(putEventUpdate(eventData));
      }
    }
  };

  console.log("Event Data", eventData);

  return (
    <div className="add-event">
      <div className="head-text">
        <h1>Welcome!</h1>
        <h2>Add new Event you want to organize with us.</h2>
      </div>

      <div className="all-details">
        <div className="basic">
          <h2>Basic Details</h2>

          <BasicFields data={eventData} update={setEventData} />
        </div>
        <div className="media">
          <h2>Media</h2>

          <MediaFields data={eventData} update={setEventData} />
        </div>

        {/* 
        <div className="speakers-n-orgs">
          <h2>Speaker and Organizations</h2>

          <OrgSpeakerFields eventData={eventData} setEventData={setEventData} />
        </div>
        */}
        <div className="settings">
          <h2>Settings</h2>

          <SettingFields data={eventData} update={setEventData} />
        </div>
        {/*<div className="questions">
          <h2>Questions</h2>

          <QuestionFields data={eventData} update={setEventData} />
      </div>*/}
      </div>

      <div className="submit">
        {err.status && (
          <Alert severity="error">
            <AlertTitle style={{ textAlign: "left" }}>Incomplete Data</AlertTitle>
            {err.message}
          </Alert>
        )}
        {loading || loadingUpdate ? (
          <CircularProgress />
        ) : (
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        )}
      </div>

      <Dialog
        open={success || successUpdate}
        onClose={() => window.location.reload()}
      >
        <DialogContent>
          <h2>Event Created Successfully!!</h2>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => (window.location.href = "/event-list")}
            variant="contained"
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LayoutsSide(EditEvent);
