import React from "react";
import { Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "500ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#7F00BB" : "#7F00BB",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#7F00BB",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SettingFields: React.FC<{ data: any; update: any }> = ({ data, update }) => {
  const changeHandler = (input: any) => {
    const tempData = { ...data };
    tempData.event_settings[input] = !tempData.event_settings[input];
    update(tempData);
  };

  const trainingHandler = () => {
    const tempData = { ...data };
    tempData.training = !tempData.training;
    update(tempData);
  };
  return (
    <div className="settings_fields">
      <div className="row">
        <Typography variant="h6" component="h6">
          Hidden
        </Typography>
        <IOSSwitch
          checked={data.event_settings.hidden}
          onChange={() => changeHandler("hidden")}
        />
      </div>
      <div className="row">
        <Typography variant="h6" component="h6">
          Show Registrations
        </Typography>
        <IOSSwitch
          checked={data.event_settings.show_registration}
          onChange={() => changeHandler("show_registration")}
        />
      </div>
      <div className="row">
        <Typography variant="h6" component="h6">
          Show Registrations Count
        </Typography>
        <IOSSwitch
          checked={data.event_settings.show_registration_count}
          onChange={() => changeHandler("show_registration_count")}
        />
      </div>

      <div className="row">
        <Typography variant="h6" component="h6">
          Training
        </Typography>
        <IOSSwitch checked={data.training} onChange={trainingHandler} />
      </div>
    </div>
  );
};

export default SettingFields;
