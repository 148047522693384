import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const RoleIndusFields: React.FC<{ data: any; update: any }> = ({
  data,
  update,
}) => {
  const basicChangeHandler = (newVal: string[], input: string) => {
    update({
      ...data,
      [input]: newVal,
    });
  };
  return (
    <div className="role-n-industries_fields">
      <Autocomplete
        multiple
        disableClearable
        freeSolo
        id="tags-outlined"
        className="roles"
        value={data.job_roles}
        onChange={(e, newVal) => basicChangeHandler(newVal, "job_roles")}
        options={["Growth Equity", "Private Credit", "Hedge Fund"]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add roles here (Type and press Enter)"
            placeholder="Roles"
          />
        )}
      />
      <Autocomplete
        multiple
        disableClearable
        freeSolo
        id="tags-outlined"
        value={data.industries}
        onChange={(e, newVal) => basicChangeHandler(newVal, "industries")}
        options={["Growth Equity", "Private Credit", "Hedge Fund"]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add industries here (Type and press Enter)"
            placeholder="Industries"
          />
        )}
      />
    </div>
  );
};

export default RoleIndusFields;
