import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


import { getJobList, getJobsSummary } from "../../redux/Actions/jobActions";
import UniversityCard from "../../components/JobDashboard/UniversityCard";
import LayoutsSide from "../../components/Layout/LayoutsSide";

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="#000000"
        >{`${Math.round(props.value)}`}</Typography>
      </Box>
    </Box>
  );
}

const JobDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<number[]>([]);
  const [jobId, setJobId] = useState("");
  const data: any = [];
  const key: any = [];
  const application_status: any = [];

  useEffect(() => {
    dispatch(getJobList());
  }, []);

  const { userInfo } = useSelector((state: any) => state.userDetails);
  const { jobList, loading, error } = useSelector(
    (state: any) => state.jobList
  );
  const { jobSummary, loading1, error1 } = useSelector(
    (state: any) => state.jobSummary
  );
  // console.log("jobList", jobList);
  let group = "application_status";
  useEffect(() => {
    if (jobId !== "")
      dispatch(getJobsSummary(jobId, group));
  }, [jobId]);

  // console.log("jobSummary", jobSummary);

  const application_status1 = (id: any) => {
    if (id === 0) return "Not Yet Reviewed";
    if (id === 2) return "Maybe";
    if (id === 1) return "Decline";
    if (id === 3) return "Invite for Interview";
    if (id === 4) return "Offer";
    if (id === 5) return "Offer Accepted";
  };

  jobSummary?.records?.map((university: any) => key.push(university?._id));

  function mapAry(name: any, val: any, index: any) {
    var obj: any = {};

    obj["id"] = name;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["University"] = name;
    data.push(obj);
  }

  jobSummary?.records.map((university: any, index: any) =>
    mapAry(university._id, university.count, index)
  );

  console.log(jobSummary)

  // for aaplication_status

  function mapAry2(name: any, val: any, index: any) {
    if (checked.includes(name)) {
      var obj: any = {};

      obj["id"] = application_status1(name);
      obj[`${application_status1(name)}`] = val;
      obj["value"] = val;

      application_status.push(obj);
    }
  }

  jobSummary?.records.map((university: any, index: any) =>
    mapAry2(university._id, university.count, index)
  );

  // console.log("aaa", data);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div className="job-dashboard">
      <div className="name-card">
        <div className="name">
          <h1>Hello, {userInfo?.first_name} 👋 </h1>
          <p>
            You have {jobSummary?.total_count} applicants.{" "}
          </p>
        </div>
        <div className="img">
          <img
            src="https://ecn.blob.core.windows.net/ecn/images/common/Saly-38.png"
            alt=""
          />
        </div>
      </div>

      <div className="job-select">
        <FormControl sx={{ m: 1, width: 500 }}>
          <Select
            onChange={(e) => setJobId(e.target.value)}
            defaultValue="0"
            autoWidth
            style={{
              color: "#7F00BB",
              background: "#F8F9FA",
              height: "40px",
              fontSize: "16px",
              fontWeight: "600",
              width: "400px",
              overflow: "hidden",
              maxWidth: 500
            }}
          >
            <MenuItem value="0" disabled>
              Select the Job
            </MenuItem>
            {jobList?.records?.map((job: any, index: number) => (
              <MenuItem key={index} value={job?._id}>
                {job?.job_title} - {job?._id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="date">

        
      </div>
      <div className="cards">
        <div className="card">
          <div className="text">
            <p>Total Attendees</p>
            <h1>{jobSummary?.total_count}</h1>
            <h3>Till now</h3>
          </div>
          <div className="progress">
            <CircularProgressWithLabel value={jobSummary?.total_count} />
          </div>
        </div>

        <div className="card">
          <div className="text" style={{ minWidth: "210px" }}>
            <p>Total number of universities</p>
            <h1>
              <UniversityCard />
            </h1>
            <h3>Till now</h3>
          </div>
          <div></div>
        </div>

        <div className="custom ">
          <button>Custom Stats</button>
        </div>
      </div>

      <div className="graph-h1">
        <h1>Job board</h1>
        <div className="graph">
          <div className="bar-graph">
            <ResponsiveBar
              data={data}
              keys={key}
              indexBy="id"
              colors={[
                "#FCA3CC",
                "#BEAEE2",
                "#FCD8D4",
                "#FFAFAF",
                "#D77FA1",
                "#EDD2F3",
                "#FFDEFA",
                "#F5ABC9",
                "#FDE0DD",
                "#FFC288",
                "#B8B5FF",
                "#F190B7",
                "#FCD1D1",
                "#6FB2D2",
                "#F473B9",
                "#F7F7F7",
                "#FCF8EC",
                "#D2F5E3",
                "#FFC1F3",
                "#FCCBCB",
              ]}
              colorBy="id"
              margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
              padding={0.3}
              innerPadding={5}
              layout="vertical"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              borderRadius={12}
              borderColor={{
                from: "color",
                modifiers: [["darker", 3]],
              }}
              animate={true}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,

                legendPosition: "middle",
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              legends={
                [
                  // {
                  //   dataFrom: "keys",
                  //   anchor: "bottom-right",
                  //   direction: "column",
                  //   justify: false,
                  //   translateX: 120,
                  //   translateY: 0,
                  //   itemsSpacing: 2,
                  //   itemWidth: 100,
                  //   itemHeight: 20,
                  //   itemDirection: "left-to-right",
                  //   itemOpacity: 0.85,
                  //   symbolSize: 10,
                  // },
                ]
              }
              theme={{
                fontSize: 15,
                grid: {
                  line: {
                    stroke: "#ffff",
                    strokeWidth: 0,
                    strokeDasharray: "6",
                  },
                },
                axis: {
                  legend: {
                    text: {
                      fontSize: 20,
                      fontWeight: 600,
                      fontFamily: "Poppins, sans-serif",
                    },
                  },
                },
              }}
            />
          </div>

          <div className="check-pie">
            <h1>Status Based</h1>
            <div className="check">
              <div className="checkbox1">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Not Yet Reviewed"
                    value="Not Yet Reviewed"
                    onChange={handleToggle(0)}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Invite for Interview"
                    value="Invite for Interview"
                    onChange={handleToggle(3)}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Offer Accepted"
                    value="Offer Accepted"
                    onChange={handleToggle(5)}
                  />
                </FormGroup>
              </div>
              <div className="checkbox2">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Decline"
                    onChange={handleToggle(1)}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Maybe"
                    onChange={handleToggle(2)}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Offer"
                    onChange={handleToggle(4)}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="pie">
              <ResponsivePie
                data={application_status}
                margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
                startAngle={-88}
                endAngle={92}
                innerRadius={0.6}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                enableArcLinkLabels={false}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={1}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                colors={[
                  "#FCA3CC",
                  "#BEAEE2",
                  "#FCD8D4",
                  "#FFAFAF",
                  "#D77FA1",
                  "#EDD2F3",
                  "#FFDEFA",
                  "#F5ABC9",
                  "#FDE0DD",
                  "#FFC288",
                  "#B8B5FF",
                  "#F190B7",
                  "#FCD1D1",
                  "#6FB2D2",
                  "#F473B9",
                  "#F7F7F7",
                  "#FCF8EC",
                  "#D2F5E3",
                  "#FFC1F3",
                  "#FCCBCB",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutsSide(JobDashboard);
