import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Alert, Dialog, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { certificateIssue } from "../../redux/Actions/eventActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "34vw",
    borderRadius: "10px",
    // textAlign: "center",
    padding: "30px 30px",
    "&::-webkit-overflow-scrolling": { width: "0px!important" },
    [theme.breakpoints.only("xs")]: {
      maxHeight: "95%",
      maxWidth: "95vw",
      margin: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      maxHeight: "100%",
      maxWidth: "79vw",
    },
  },
  confirmPop: {
    minWidth: "20vw",
    padding: "20px 30px",
    borderRadius: "10px",
    textAlign: "center",
  },
  button: {
    outline: "none",
    borderRadius: "8px",
    background: "#313131",
    color: "#ffffff",
    padding: "10px 30px",
    cursor: "pointer",
    marginBottom: "30px",
  },
  textField: {
    width: "100%",
  },
  head: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#000000",
    marginBottom: "10px",
  },
  buttons: {
    display: "flex",
    alignItem: "center",
    justifyContent: "right",
    gap: "20px",
    marginTop: "20px",
  },
  submit: {
    outline: "none",
    borderRadius: "8px",
    background: "#313131",
    color: "#ffffff",
    padding: "7px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    outline: "none",
    borderRadius: "8px",
    background: "transparent",
    border: "2px solid rgba(0,0,0,0.2)",
    color: "#000000",
    padding: "7px 20px",
    cursor: "pointer",
    fontWeight: "600",
  },
  conFirmbuttons: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
  },
  headSure: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#000000",
    marginBottom: "10px",
  },
  mainButton: {
    textAlign: "right",
    display: "flex",
    position: "relative",
    left: "75%",
    cursor: "pointer",
    zIndex: "1111",
    width: "260px",
    marginBottom: "-106px",
  },
}));

const CertificateIssue: React.FC<{
  eventType: any;
  events: any;
  eventLists: any;
  eventName: any;
  eventDate: any;
}> = ({ eventType, events, eventLists, eventName, eventDate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const [confirmPop, setConfirmPop] = useState(false);
  const [textData, setTestData] = useState<any>("");
  const [error, setError] = useState<any>(false);

  const { userInfo } = useSelector((state: any) => state.userDetails);

  // useEffect(() => {
  //   eventLists?.records?.map((event: any) => {
  //     if (event?._id === eventType) {

  //     }
  //   });
  // }, [eventType,eventLists]);

  useEffect(() => {
    let tempData: any = [];
    events?.records?.map((item: any, index: any) => {
      let first_Name = item?.created_by?.first_name;
      let last_Name = item?.created_by?.last_name;
      let id = item?.created_by?._id;
      let reg_id = item?._id;
      let date = moment(eventDate).format("dddd, MMMM DD, YYYY");

      tempData.push({
        template: "",
        event_id: eventType,
        user_id: id,
        first_name: first_Name,
        last_name: last_Name,
        heading: textData,
        event_name: eventName,
        org_name: "Elevate Career Network",
        registration_id: reg_id,
        issue_date: date,
        valid_date: "",
      });
    });
    setData(tempData);
  }, [events, textData]);

  const headingHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
    setTestData("");
  };
  const confirmationHandler = () => {
    if (textData.length > 0) {
      setConfirmPop(true);
    } else {
      setError(true);
    }
  };
  const NoHandler = () => {
    setConfirmPop(false);
  };
  const textHandler = (e: any) => {
    setTestData(e.target.value);
  };
  const yesHandler = () => {
    dispatch(certificateIssue(data));
    setConfirmPop(false);
    setOpen(false);
    setTestData("");
  };
  console.log(data);
  return (
    <div>
      <div className={classes.mainButton}>
        <button className={classes.button} onClick={headingHandler}>
          Certificate Issue
        </button>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <div>
            <h3 className={classes.head}>Enter the Heading of Certificate</h3>
            <TextField
              id="outlined-basic"
              size="small"
              onChange={textHandler}
              variant="outlined"
              className={classes.textField}
            />
            <div className={classes.buttons}>
              <button className={classes.cancelButton} onClick={handleClose}>
                Cancel
              </button>
              <button className={classes.submit} onClick={confirmationHandler}>
                Submit
              </button>
            </div>
            {error && <Alert severity="error">Field are Require </Alert>}
          </div>
        </Dialog>

        <Dialog
          open={confirmPop}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.confirmPop }}
        >
          <div>
            <p className={classes.headSure}>Are You Sure ? </p>
            <div className={classes.conFirmbuttons}>
              <button
                className={classes.cancelButton}
                onClick={NoHandler}
                style={{ padding: "8px 35px" }}
              >
                No
              </button>
              <button
                className={classes.submit}
                style={{ padding: "8px 35px" }}
                onClick={yesHandler}
              >
                Yes
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default CertificateIssue;
