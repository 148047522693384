import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileImage } from "../../redux/Actions/userActions";

import {
  Slider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { Collections, CameraAlt, Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

const CustomDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const styles = makeStyles((theme) => ({
  imgbody: {
    maxWidth: "240px",
    maxHeight: "100px!important",
    margin: "auto",
    overflow: "none",
    img: {
      maxWidth: "90%",
      maxHeight: "90%",
    },
  },
  input: {
    left: 0,
    top: 0,
    opacity: 0,
    position: "absolute",
    fontSize: "90px",
  },
  inputBody: {
    display: "inline-block",
    position: "relative",
    overflow: "hidden",
  },
  btnupload: {
    background: " #fff",
    border: "3px solid #000",
    color: "#000",
    padding: "7px 25px",
    borderRadius: "10px",
    fontSize: "14px",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30px",
  },
  icon: {
    marginRight: "13px",
    fontSize: "16px!important",
    color: "blue",
  },
  wr: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  addButton: {
    background: "#ffff!important",

    marginTop: "375px!important",
  },
  sliderBox: {
    marginTop: "-85px!important",
    width: "240px",
    margin: "auto",
    slider: {
      color: "red!important",
    },
  },
  save: {
    background: "#183A59",
    color: "#ffff",
    padding: 8,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
    border: "none",
    margin: "auto",
    borderRadius: 8,
    display: "block",
  },
  selectInput: {
    border: "none",
    outline: "none",
    borderBottom: "1px solid #183A59",
    padding: "2px",
    background: "background.paper",
    margin: "5px",
  },
  selectImg: {
    width: "240px",
    height: "80px",
  },
  bannerImg: {
    width: "100%",
    height: "400px",
    margin: "auto",

    img: {
      maxWidth: "40%",
      maxHeight: "100%",
    },
  },
  testfield: {
    marginBottom: "20px!important",
  },
  editHead: {
    fontSize: "20px",
    color: "#7F00BB",
    fontWeight: "600",
  },
  subp: {
    fontSize: "10px",
  },
  editProfile: {
    margin: "10px",
    edu: {
      margin: "10px",
    },
  },
  editProfiledat: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  savebutton: {
    background: "#183A59 !important",
    color: "#ffff !important",
    marginLeft: "25vw!important",
  },
  edu: {
    width: "50%",
    paddingRight: "30px",
    borderRight: "2px solid #999999",
  },
  car: {
    width: "50%",
    paddingLeft: "40px",
  },
  inputName: {
    fontSize: "12px",
    fontWeight: "600",
    marginTop: "20px",
    color: "#555555",
  },
}));

const ProfileImg: React.FC<{ id: string; userInfo: any }> = ({
  id,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const classes = styles();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [{ alt, src }, setImg] = useState({
    src: userInfo?.display_pictures && userInfo?.display_pictures[0],
    alt: "Upload an Image",
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // conver blob into file
  const convertToFile = (base64: string) => {
    let arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const extension = mime.split("/")[1];
    const fileName = new Date().getTime() + `.${extension}`;
    return new File([u8arr], fileName, { type: mime });
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(src, croppedAreaPixels);

      setCroppedImage(croppedImage);
      setOpen(false);
      setSelected(false);

      const img = convertToFile(croppedImage);
      const formData = new FormData();
      formData.append("file", img);
      formData.append("userid", id);

      dispatch(updateProfileImage(formData));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
    }

    setSelected(true);
  };

  const handleOpen1 = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
    setSelected(false);
  };

  return (
    <div className="profile">
      <img
        src={
          croppedImage
            ? croppedImage
            : userInfo?.display_pictures?.length > 0
            ? userInfo?.display_pictures[1]
            : "https://ecn.blob.core.windows.net/ecn/images/common/images.png"
        }
        className="user-img"
      />

      <CameraAlt className="camera" onClick={handleOpen1} />

      <Dialog open={open} onClose={handleClose1} maxWidth="md">
        <CustomDialogTitle onClose={handleClose1}>
          <h1 style={{ fontSize: "20px" }}>Profile Photo</h1>
        </CustomDialogTitle>
        <DialogContent>
          <Box sx={modal}>
            <div>
              {!selected ? (
                <div
                  style={{
                    width: "400px",
                    height: "400px",
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <img
                    style={{ borderRadius: "100%", margin: "auto" }}
                    src={
                      userInfo?.display_pictures &&
                      userInfo?.display_pictures[2]
                    }
                    // className={classes.selectImg}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    cropShape="round"
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropSize={{ width: 300, height: 300 }}
                    style={{
                      mediaStyle: { height: "100%" },
                      containerStyle: { maxHeight: "300px", margin: "auto" },
                    }}
                  />
                </div>
              )}
            </div>

            <div className={classes.wr}>
              {/* {!selected && (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => setSelected(true)}
                    style={{ color: "black", borderColor: "black" }}
                  >
                    Edits
                  </Button>
                )} */}
              {!selected && (
                <div className={classes.inputBody} style={{ color: "black" }}>
                  <button className={classes.btnupload}>
                    {" "}
                    <Collections
                      className={classes.icon}
                      style={{ color: "black" }}
                    />{" "}
                    Add Photo
                  </button>

                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImg}
                    className={classes.input}
                  />
                </div>
              )}
              {selected && (
                <Button
                  onClick={showCroppedImage}
                  startIcon={<Collections />}
                  className={classes.addButton}
                  style={{ color: "black" }}
                >
                  Save Photo
                </Button>
              )}
            </div>

            {selected && (
              <div className={classes.sliderBox}>
                {" "}
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(event: any) => setZoom(event.target?.value)}
                  style={{ color: "black" }}
                />
              </div>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProfileImg;

const modal = {
  width: 800,
  height: "450px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
  img: {
    height: "220px",
    with: "600px",
  },
  MuiAutocomplete: {
    padding: "0px 6px !important",
  },
};
