import React from "react";
import { Button, TextField } from "@mui/material";
import ProfileImg from "../ImageCropper/ProfileImg";
import BannerImg from "../ImageCropper/BannerImg";

const OverviewFields: React.FC<{
  userData: any;
  setUserData: any;
  userId: string;
}> = ({ userData, setUserData, userId }) => {
  const basicDetailHandler = (e: any, input: any) => {
    setUserData({ ...userData, [input]: e.target.value });
  };
  return (
    <div className="overview_fields">
      <div className="images">
        <ProfileImg id={userId} userInfo={userData} />
        <BannerImg id={userId} userInfo={userData} />
      </div>
      <div className="row">
        <TextField
          label="Bio"
          value={userData.bio}
          onChange={(e) => basicDetailHandler(e, "bio")}
        />
        <TextField
          label="Headline"
          value={userData.headline}
          onChange={(e) => basicDetailHandler(e, "headline")}
        />
        <Button>View Resume</Button>
      </div>
    </div>
  );
};

export default OverviewFields;
